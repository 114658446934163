import { combineReducers } from 'redux'
import authslice from '../slice/authslice'
import getShopDatSlice from '../slice/getShopDatSlice'
import getAllOrderSlice from '../slice/getAllOrderSlice'
import { getOrderHistoryById } from '../service/getOrderHistoryById'
import updateCarrierSlice from '../slice/updateCarrierSlice'
import getAllNumberSlice from '../slice/getAllNumberSlice'
import addOrderNumberSlice from '../slice/addOrderNumberSlice'
import updateNumberSlice from '../slice/updateNumberSlice'
import getAllOrderManagementSlice from '../viewallslice/getAllOrderManagementSlice'
import updateManagementStatusSlice from '../slice/updateManagementStatusSlice'
import getAllcustomerSlice from '../slice/getAllcustomerSlice'
import adddNewClientSlice from '../slice/adddNewClientSlice'
import updateClientSlice from '../slice/updateClientSlice'
import getClientbyIdSlice from '../slice/getClientbyIdSlice'
import getOrderTrackingSlice from '../viewallslice/getOrderTrackingSlice'
import addOrderTrackingSlice from '../viewallslice/addOrderTrackingSlice'
import getCreditManagementSlice from '../viewallslice/getCreditManagementSlice'
import addCreditManagementSlice from '../viewallslice/addCreditManagementSlice'
import getAllCarrierSlice from '../slice/getAllCarrierSlice'
import editCustomerSlice from '../slice/editCustomerSlice'
import getCustomerByIdSlice from '../slice/getCustomerByIdSlice'
import getAllcustomerHistorySlice from '../slice/getAllcustomerHistorySlice'
import newAddcustomerOrderSlice from '../slice/newAddcustomerOrderSlice'
import getDisByIdSlice from '../slice/getDisByIdSlice'
import getWeekDashSlice from '../slice/getWeekDashSlice'
import { getAllDashboardService } from '../service/getDashboardService'
import getDashboardSlice from '../slice/getDashboardSlice'
import getAllCreditSlice from '../slice/getAllCreditSlice'
import getOrderDataByIdSlice from '../slice/getOrderDataByIdSlice'
import getSeliingPriceSlice from '../slice/getSeliingPriceSlice'
import getAllTheCopyCarrierByDistributerSlice from '../slice/getAllTheCopyCarrierByDistributerSlice'
import updateSellingpriceSlice from '../slice/updateSellingpriceSlice'
import getAllcarrierPriceSlice from '../slice/getAllcarrierPriceSlice'
import getAllareacodeSlice from '../slice/getAllareacodeSlice'
import getpricebydisSlice from '../slice/getpricebydisSlice'
import getAllpriceForCustomerEditSlice from '../slice/getAllpriceForCustomerEditSlice'
import getSimCardbyIdSlice from '../slice/getSimCardbyIdSlice'
import deleteCustomerSlice from '../slice/deleteCustomerSlice'
import deletecustomerOrderSlice from '../slice/deletecustomerOrderSlice'
import getCustomerOrderTrackingSlice from '../slice/getCustomerOrderTrackingSlice'
import UploadFileSlice from '../slice/UploadFileSlice'
import UploadBulkUploadDistributorSlice from '../slice/UploadBulkUploadDistributorSlice'
import contactusSlice from '../slice/contactusSlice'
const rootReducer=combineReducers({
    authslice:authslice,
    getShopDatSlice:getShopDatSlice,
    getAllOrderSlice:getAllOrderSlice,
    getOrderHistoryById:getOrderHistoryById,
    updateCarrierSlice:updateCarrierSlice,
    getAllNumberSlice:getAllNumberSlice,
    addOrderNumberSlice:addOrderNumberSlice,
    updateNumberSlice:updateNumberSlice,
    getAllOrderManagementSlice:getAllOrderManagementSlice,
    updateManagementStatusSlice:updateManagementStatusSlice,
    getAllcustomerSlice:getAllcustomerSlice,
    adddNewClientSlice:adddNewClientSlice,
    updateClientSlice:updateClientSlice,
    getClientbyIdSlice:getClientbyIdSlice,
    getOrderTrackingSlice:getOrderTrackingSlice,
    addOrderTrackingSlice:addOrderTrackingSlice,
    getCreditManagementSlice:getCreditManagementSlice,
    addCreditManagementSlice:addCreditManagementSlice,
    getAllCarrierSlice:getAllCarrierSlice,
    editCustomerSlice:editCustomerSlice,
    getCustomerByIdSlice:getCustomerByIdSlice,
    getAllcustomerHistorySlice:getAllcustomerHistorySlice,
    newAddcustomerOrderSlice:newAddcustomerOrderSlice,
    getDisByIdSlice:getDisByIdSlice,
    getWeekDashSlice:getWeekDashSlice,
    getDashboardSlice:getDashboardSlice,
    getAllCreditSlice:getAllCreditSlice,
    getOrderDataByIdSlice:getOrderDataByIdSlice,
    getSeliingPriceSlice:getSeliingPriceSlice,
    getAllTheCopyCarrierByDistributerSlice:getAllTheCopyCarrierByDistributerSlice,
    updateSellingpriceSlice:updateSellingpriceSlice,
    getAllcarrierPriceSlice:getAllcarrierPriceSlice,
    getAllareacodeSlice:getAllareacodeSlice,
    getpricebydisSlice:getpricebydisSlice,
    getAllpriceForCustomerEditSlice:getAllpriceForCustomerEditSlice,
    getSimCardbyIdSlice:getSimCardbyIdSlice,
    deleteCustomerSlice:deleteCustomerSlice,
    deletecustomerOrderSlice:deletecustomerOrderSlice,
    getCustomerOrderTrackingSlice:getCustomerOrderTrackingSlice,
    UploadFileSlice:UploadFileSlice,
    UploadBulkUploadDistributorSlice:UploadBulkUploadDistributorSlice,
    contactusSlice:contactusSlice
})

export default rootReducer