import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { basicOth } from "../baseUrl/baseUrl";

import { baseUrl } from "../baseUrl/baseUrl";
import { basicAuth } from "../baseUrl/basicAuth";
import { handleError } from "../handleError/handleError";

export const UpdateUserService = createAsyncThunk(
  "UpdateUserService",
  async (payload) => {
    const disId=localStorage.getItem('disId')
      const string=payload?.data.status
    const stribng=string==='true'
    const itspya=payload?.data.paymentModel==='true'
    const data={   
        //  "city":"newCity",
        // "state":"newState",
        // "location":"newLocation",
        "isPostPaidAccount":itspya,

        
     
      "user": {
          "name": payload?.data?.name,
          "email": payload?.data?.email,
          "password": payload?.data?.password,
          "phoneNumber": payload?.data?.Phone,
          "acountStatus": stribng,

          "createdDate": null,
          "updatedDate": null
      },
      "passAdminId":null,
      "passDistributerId": {
        "distributerId": disId
      }
  
  }
    try {
      let url = baseUrl + `updateCustomerById/${payload?.id}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.put(url, data, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
