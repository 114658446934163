


import Papa from 'papaparse';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'; 
export const exportToExcel = (filterData) => {
  // Create a new workbook and a new worksheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(filterData);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Data');

  // Generate Excel file
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // Save file using file-saver
  saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
}
export const exportToCSV = (filterData) => {
    // Convert JSON data to CSV
    const csv = Papa.unparse(filterData);

    // Create a blob and save the file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
  };