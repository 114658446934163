import react, { useEffect, useState } from 'react'
import { Button, FormControl, FormLabel, Input, Select, Spinner } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetcher, useParams } from 'react-router-dom'
import { getAllCarriersPriceByDisService } from '../service/getAllcarrirerPricesDisId'
import { UpdatePriceDisService } from '../service/updatenewPriceservice'
import { sucessToast } from '../toast/toast'
import { getAllPriceForcustomerService } from '../service/getPriceforcustomerEdit'
const EditPriceCustomer = () => {

     const {id}=  useParams()
 
    const dispatch=useDispatch()
 
    useEffect(() => {
        dispatch(getAllPriceForcustomerService(id))
    }, [id])

    const AllCarrier = useSelector((state) => state?.getAllpriceForCustomerEditSlice?.data)

    

    console.log(AllCarrier,'thgfe')
    
     const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState('');
    const [currentCarrier, setCurrentCarrier] = useState('');

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };

    const handleSave = async (e, carrier) => {
        e.preventDefault();
        setLoading(true);
        const finalPrice = price.trim() === '' ? AllCarrier[carrier] : price;

        try {
            await dispatch(UpdatePriceDisService({ carrierName: carrier, newPrice: finalPrice,id })).unwrap();
        dispatch(getAllPriceForcustomerService(id))

            sucessToast('Price Updated')
        } catch (error) {
            console.error('Failed to update price:', error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>

            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Edit Price</h3>
            </div>

            <div className='grid grid-cols-4 justify-center mx-auto gap-3 w-11/12 mx-auto'>
             { AllCarrier&&Object?.keys(AllCarrier)?.map((carrier) => (
                <div key={carrier} className='p-3' style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
                    <div className='bg-grey p-3'>
                        <h3 className='text-blue border-b bg-grey-400'>{carrier}</h3>
                    </div>
                    <div>
                        <label>User price</label>
                        <input
                            placeholder='User Price  (in $)'
                            value={currentCarrier === carrier ? price : AllCarrier[carrier]}
                            onChange={(e) => {
                                setCurrentCarrier(carrier);
                                handlePriceChange(e);
                            }}
                            name={`${carrier}-price`}
                            className='border p-2 pb-2'
                        />
                    </div>
                    <div>
                        <Button className='mt-1' onClick={(e) => handleSave(e, carrier)}>
                            {loading && currentCarrier === carrier ? <Spinner animation="border" /> : 'Save'}
                        </Button>
                    </div>
                </div>
            ))}
            </div>

        </>
    )
}

export default EditPriceCustomer