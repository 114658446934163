import react, { useEffect, useState } from 'react'
import { FormControl, FormLabel, Select, Spinner } from '@chakra-ui/react'

import { PhoneIcon, AddIcon, WarningIcon, ViewIcon, MinusIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { Button, Input } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux'

import { addnewManageMentCreditService } from '../viewAllService/addnewManagementCreditService'
import { getAllCustomerService } from '../service/getAllCustomerService'
import { getAllCreditService } from '../service/getAllCreditManageService'
import { formatDate } from './date'
import { exportToCSV, exportToExcel } from './export'

const CreditManagement = () => {
    const CustomerData = useSelector((state) => state?.getAllcustomerSlice?.data)
    const credintData = useSelector((state) => state?.getAllCreditSlice?.data)


    const dispatch = useDispatch()
    const [filterData, setFilterData] = useState([])
    const [searchValue, setInputvalue] = useState('')


    const [data, setData] = useState({
        clientName: '',
        desc: '',
        amount: '',
        satus: ""

    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }


    const handleClick = async (refund) => {
        if(data?.amount==0){
            return alert('Price cant be 0')
        }else if(data?.clientName==''){
            return alert('Please select customer')

        }
        await dispatch(addnewManageMentCreditService({ data, refund })).unwrap()
        dispatch(getAllCreditService())

    }

    const handleforRefund = async (refund) => {

        if(data?.amount==0){
            return alert('Price cant be 0')
        }else if(data?.clientName==''){
            return alert('Please select customer')

        }
        const response = await dispatch(addnewManageMentCreditService({ data, refund })).unwrap()
        console.log(response, 'jksjkcbjbchsj')
        dispatch(getAllCreditService())

    }

    useEffect(() => {
        dispatch(getAllCustomerService())
        dispatch(getAllCreditService())
    }, [dispatch])

    const copyToClipboard = () => {
        // Define headers
        const headers = ['Name', 'Email', 'Order Quantuty', 'Area code'];

        // Prepare the text data
        let textData = '';

        // Add headers
        textData += headers.join('\t') + '\n'; // Use tab (\t) for Copying options, newline (\n) for rows

        // Add rows from filterData
        filterData.forEach(item => {
            // Ensure each item has all the required fields
            const row = [
                item?.dealerName || '',       // Ensure default value if missing
                item?.dealerEmail || '',  // Ensure default value if missing
                item?.orderedQuantity || '',   // Ensure default value if missing
                item?.areaCode || '',   // Ensure default value if missing

            ].join('\t'); // Use tab (\t) for Copying options
            textData += row + '\n'; // Add a newline for each row
        });
        navigator.clipboard.writeText(textData).then(
            () => alert('Table data copied to clipboard!'),
            (err) => console.error('Failed to copy table data: ', err)
        );
    };

    return (
        <>
            <style>
                {
                    `
                    
                 th{
                background:#FBB04B;
                color:white;
                text-align:center;
                 }
                td{
               text-align:center; 
                }

                
                `
                }
            </style>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto p-2 text-xl max-[768px]:text-center">Credit Management</h3>
            </div>

            <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-blue-500 font-medium'>Add Credits for Customer</h3>
                    </div>
                    <div className='p-5'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customer List</FormLabel>
                                <Select placeholder='Select Customer' name='clientName' onChange={handleChange}>
                                    {
                                        Array.isArray(CustomerData) && CustomerData?.map((item) => {
                                            return (
                                                <>
                                                    <option value={item?.customerId}>
                                                        {item?.customerName}
                                                    </option>
                                                </>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}></FormLabel>
                                <Input placeholder="Customer Payment Mode (in $)" name='desc' onChange={handleChange}></Input>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Amount</FormLabel>
                                <Input type='number' placeholder="Amount (in $)" name='amount' onChange={handleChange}></Input>
                            </FormControl>

                        </div>

                        <div>
                        </div>
                        <div className='flex gap-3 mt-2 mx-1'>

                            <button type='button' onClick={() => handleClick('Add Credits')} class="bg-[#8A8D56] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">

                                <AddIcon />  Add Credits

                            </button>


                            <button onClick={() => handleforRefund('Refund')} class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                <MinusIcon /> Refund
                            </button>
                        </div>

                    </div>

                </div>

            </div>
            <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                <div>
                    <p style={{ color: '' }} >Copying options</p>
                </div>
                <div>
                    <Button onClick={() => exportToExcel(filterData)}>
                        <img src={exportpng} />
                        Excel
                    </Button>
                </div>
                <div>
                    <Button onClick={() => exportToCSV(filterData)}>
                        <img src={exportpng} />
                        CSV
                    </Button>
                </div>
                <div>
                    <Button onClick={copyToClipboard}>
                        <img src={exportpng} />
                        Copy
                    </Button>
                </div>
            </div>

            <body class="w-full mx-auto bg-gray-100">
                <div class="w-full mx-auto mt-5 overflow-x-auto">
                    <div class="overflow-y-auto max-h-[50vh]">
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3">Id</th>
                                    <th scope="col" class="px-6 py-3">Ref</th>
                                    <th scope="col" class="px-6 py-3">Description</th>
                                    <th scope="col" class="px-6 py-3">Amount</th>
                                    <th scope="col" class="px-6 py-3">Status</th>

                                    <th scope="col" class="px-6 py-3">Created At</th>
                                    <th scope="col" class="px-6 py-3">Dealer Email</th>

                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                {
                                    Array.isArray(credintData) && credintData?.map((item, index) => {
                                        return (
                                            <>
                                                <tr class="border-b">
                                                    <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{index + 1}</td>
                                                    <td class="px-6 py-4">{item?.name}</td>
                                                    <td class="px-6 py-4">{item?.description}</td>

                                                    <td className={`px-6 py-4 font-medium ${item.status === 'Refund' ? 'text-red-500' : 'text-green-500'}`}>
                                                       ${item?.amount}
                                                    </td>

                                                    <td class="px-6 py-4 font-medium" >{item?.status === 'InActive' ? 'Pending' : item?.status}  </td>

                                                    <td class="px-6 py-4">{formatDate(item?.createdDate)}  </td>

                                                    <td class="px-6 py-4">{item?.dealerEmail}</td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }


                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

        </>
    )
}

export default CreditManagement