import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { basicAuth } from "../baseUrl/basicAuth";
import { handleError } from "../handleError/handleError";
import { errorToast, sucessToast } from "../toast/toast";
import { baseUrl } from "../baseUrl/baseUrl";


export const addCostomerOrderNumber = createAsyncThunk(
  "addCostomerOrderNumber",
  async (payload) => {
    const adminID=localStorage.getItem('adminId')
    console.log(payload,'scjhsckjb')
    const data=
    {
        "order": {
            "orderId":payload?.orderId
        },
        "phoneNumber": payload?.newDatadata?.planNumber,
        "pin": payload?.newDatadata?.transerPin,
        "accountNumber": payload?.newDatadata?.accountNumber,
        "name": "John Doe",
        "address": "123 Main St, Anytown, USA",
        "birthDate": payload?.newDatadata?.expiryDate,
        "status": true,
        "createdDate": null,
        "updateddate": null,
        "notes": "Test note"
       
    }
    
    try {
      let url = baseUrl + 'addSimCardNumber';
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.post(url, data, config); 
     sucessToast(res?.data)
      return res.data;
    } catch (error) {
      console.log(error)
      errorToast('You dont have Enough Carrier'); // Pass the entire error object to the handleError function

    }
  }
);
