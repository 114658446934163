import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the components needed for the chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Function to generate day names for the last 7 days including today
const getDayLabels = () => {
  const labels = [];
  const now = new Date();
  
  // Generate labels for the last 7 days
  for (let i = 6; i >= 0; i--) {
    const date = new Date(now);
    date.setDate(now.getDate() - i);
    
    // Get the day name (e.g., Wednesday)
    const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
    labels.push(dayName);
  }
  
  return labels;
};

// Function to convert date string to day name
const dateToDayName = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { weekday: 'long' });
};

// Function to aggregate orders by carrier name and day
const aggregateCarrierOrders = (weekdata, labels) => {
  const carrierOrders = {};

  // Initialize carrierOrders structure with carrier names and days
  labels.forEach((day, dayIndex) => {
    const date = new Date();
    date.setDate(date.getDate() - (6 - dayIndex));
    const formattedDate = date.toISOString().split('T')[0];
    
    const dayData = weekdata.find(data => data.date === formattedDate);
    
    if (dayData) {
      dayData.carriers.forEach(carrier => {
        if (!carrierOrders[carrier.carrierName]) {
          carrierOrders[carrier.carrierName] = Array(labels.length).fill(0);
        }

        carrierOrders[carrier.carrierName][dayIndex] = carrier.quantity;
      });
    }
  });

  return carrierOrders;
};

const WeekChart = ({ weekdata }) => {
  // Get day names for the last 7 days
  const labels = getDayLabels();

  // Aggregate orders by carrier name and day
  const carrierOrders = aggregateCarrierOrders(weekdata, labels);

  // Prepare datasets for each carrier
  const datasets = Object.keys(carrierOrders).map(carrierName => ({
    label: carrierName,
    data: carrierOrders[carrierName],
    backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`,
    borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
    borderWidth: 1,
  }));

  // Chart data and options
  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    scales: {
      x: {
        stacked: true, // Stack the bars on the x-axis
      },
      y: {
        beginAtZero: true,
        stacked: true, // Stack the bars on the y-axis
      },
    },
    plugins: {
      legend: {
        position: 'top', // Position the legend at the top
      },
    },
  };

  return (
    <div style={{ width: '70%', height: '300px', margin: '0 auto' }}>
          <div className="flex  mt-5">
<h3 className="font-medium text-2xl">
Weekly Purchased Details

</h3>
        </div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default WeekChart;
