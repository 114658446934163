import react from 'react'
import { getSellingPriceByID } from "../service/getSellingPriceByIdService";
import { useParams } from 'react-router-dom';
import { Box, FormControl, Input, Typography } from '@mui/material';

const SellingPriceEdit=()=>{
    const {id}=useParams()
    return(
<>
<Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="100vh"
    >
      <Typography variant="h5" gutterBottom>
        Selling Price
      </Typography>
      <Box width="50%" maxWidth={400}>
        <FormControl fullWidth>
          <Input placeholder='Selling Price' />
        </FormControl>
      </Box>
    </Box>

</>
    )
}

export default SellingPriceEdit