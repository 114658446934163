import React, { useEffect } from "react";
import { PhoneIcon, AddIcon, WarningIcon, ViewIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { getOrderManageMentService } from "../viewAllService/getOrderManagementService";
import { Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { UpdateOrderManagementStatus } from "../service/updateOrderManagementStatusService";

const OrderManagement = () => {
    const data=useSelector((state)=>state)
    useEffect(()=>{

    },[])
    return (
        <>
            <style>
                {

                    `
                th{
                text-align:center;
                border-right:1px solid white;
                background:rgba(82, 87, 214, 1);
                color:white;
                }
                  td{
                text-align:center;
                }


                
                `
                }
            </style>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Order Management</h3>
            </div>

            <div className="mt-5 w-[98%] mx-auto " style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <div className="flex justify-between items-center w-[98%] p-2 mx-auto">
                    <div className="">
                        <h1 className="font-[700] " style={{ color: '#8A8D56' }}> Your Order List</h1>
                    </div>
                    <div className="">
                       
                    </div>
                </div>
            </div>


            <div className="">
                <div className="w-[98%]  max-[768px]:flex-wrap  mx-auto mt-4 flex gap-2 items-center justify-between mb-4 max-[768px]:justify-center max-[768px]:w-[94%] ">
                    <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: 'rgba(18, 168, 78, 1)' }} className="text-[rgba(18, 168, 78, 1)]">Copying options</p>
                        </div>
                        <div>
                            <Button>
                                <img src={exportpng} />
                                Export
                            </Button>
                        </div>
                        <div>
                            <Button>
                                <img src={exportpng} />
                                CSV
                            </Button>
                        </div>
                        <div>
                            <Button>
                                Copy
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div class='max-w-md mx-auto max-[768px]:flex '>
                            <div style={{ border: '1px solid rgba(82, 87, 214, 1)' }} class="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>

                                <input
                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 "
                                    type="text"
                                    id="search"
                                    placeholder="Search something.." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <body class="w-full mx-auto bg-gray-100">
                <div class="w-full mx-auto mt-5 overflow-x-auto">
                    <div class="overflow-y-auto max-h-[50vh]">
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-justify">Ref</th>
                                    <th scope="col" class="px-6 py-3">Carier</th>
                                    <th scope="col" class="px-6 py-3">Quantity</th>
                                    <th scope="col" class="px-6 py-3">Area Code</th>
                                    <th scope="col" class="px-6 py-3">Zip Code </th>
                                    <th scope="col" class="px-6 py-3">Cost </th>
                                    <th scope="col" class="px-6 py-3">Status</th>
                                    <th scope="col" class="px-6 py-3">Status Description</th>
                                    <th scope="col" class="px-6 py-3">Action</th>
                                    <th scope="col" class="px-6 py-3">Change Status</th>



                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <tr class="border-b">
                                    <td class="px-6 text-justify py-4 font-medium text-gray-900 whitespace-nowrap">
                                        <p>
                                            Id: 2500
                                        </p>
                                        <p>
                                            Name: Pawan mehra
                                        </p>
                                        <p>
                                            Email: pawan@gmail.com
                                        </p>
                                        <p>
                                            Dealer
                                        </p>
                                    </td>
                                    <td class="px-6 py-4">Verizon</td>
                                    <td class="px-6 py-4 grid grid-cols-2 gap-2 mt-[22px]">
                                        <Button className="bg-[green-500]">
                                            5
                                        </Button>
                                        <Button className="ml-1">
                                            5
                                        </Button>
                                    </td>
                                    <td class="px-6 py-4 text-green-500 font-medium">939</td>
                                    <td class="px-6 py-4 text-red-500 font-medium">300</td>
                                    <td class="px-6 py-4  font-medium">40$</td>
                                    <td class="px-6 py-4 text-blue-500 font-medium">
                                        Completed

                                    </td>
                                    <td class="px-6 py-4  font-medium">
                                        Description
                                    </td>
                                    <td class="px-6 py-4  font-medium">

                                        <button class=" text-white font-bold py-2 px-4 rounded" style={{background:'rgb(138, 141, 86)'}}>
                                            Complete Order

                                        </button>
                                    </td>
                                    <td class="px-6 py-4  font-medium">

                                        <button class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
                                            Change Status

                                        </button>
                                    </td>
                                </tr>
                                <tr class="border-b">
                                    <td class="px-6 text-justify py-4 font-medium text-gray-900 whitespace-nowrap">
                                        <p>
                                            Id: 2500
                                        </p>
                                        <p>
                                            Name: Pawan mehra
                                        </p>
                                        <p>
                                            Email: pawan@gmail.com
                                        </p>
                                        <p>
                                            Dealer
                                        </p>
                                    </td>
                                    <td class="px-6 py-4">Verizon</td>
                                    <td class="px-6 py-4 grid grid-cols-2 gap-2 mt-[22px]">
                                        <Button className="bg-[green-500]">
                                            5
                                        </Button>
                                        <Button className="ml-1">
                                            5
                                        </Button>
                                    </td>
                                    <td class="px-6 py-4 text-green-500 font-medium">939</td>
                                    <td class="px-6 py-4 text-red-500 font-medium">300</td>
                                    <td class="px-6 py-4  font-medium">40$</td>
                                    <td class="px-6 py-4 text-blue-500 font-medium">
                                        Completed

                                    </td>
                                    <td class="px-6 py-4  font-medium">
                                        Description
                                    </td>
                                    <td class="px-6 py-4  font-medium">

<button class=" text-white font-bold py-2 px-4 rounded" style={{background:'rgb(138, 141, 86)'}}>
    Complete Order

</button>
</td>
<td class="px-6 py-4  font-medium">

<button class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
    Change Status

</button>
</td>
                                </tr>
                                 <tr class="border-b">
                                    <td class="px-6 text-justify py-4 font-medium text-gray-900 whitespace-nowrap">
                                        <p>
                                            Id: 2500
                                        </p>
                                        <p>
                                            Name: Pawan mehra
                                        </p>
                                        <p>
                                            Email: pawan@gmail.com
                                        </p>
                                        <p>
                                            Dealer
                                        </p>
                                    </td>
                                    <td class="px-6 py-4">Verizon</td>
                                    <td class="px-6 py-4 grid grid-cols-2 gap-2 mt-[22px]">
                                        <Button className="bg-[green-500]">
                                            5
                                        </Button>
                                        <Button className="ml-1">
                                            5
                                        </Button>
                                    </td>
                                    <td class="px-6 py-4 text-green-500 font-medium">939</td>
                                    <td class="px-6 py-4 text-red-500 font-medium">300</td>
                                    <td class="px-6 py-4  font-medium">40$</td>
                                    <td class="px-6 py-4 text-blue-500 font-medium">
                                        Completed

                                    </td>
                                    <td class="px-6 py-4  font-medium">
                                        Description
                                    </td>
                                    <td class="px-6 py-4  font-medium">

                                        <button class=" text-white font-bold py-2 px-4 rounded" style={{background:'rgb(138, 141, 86)'}}>
                                            Complete Order

                                        </button>
                                    </td>
                                    <td class="px-6 py-4  font-medium">

                                        <button class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
                                            Change Status

                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

        </>
    )
}

export default OrderManagement