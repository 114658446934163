import react, { useState } from 'react'
import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import { AddCarrierService } from '../service/addCarrierService'
import { useSelector } from 'react-redux'
const AddCarrier=()=>{

    const [data,seData]=useState({
        carrier:"",
        price:"",
        status:""
    })
   const handleChange=(e)=>{
     const {name,value}=e.target
     seData({
        [name]:value
     })
   }

    return(
        <> <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
        <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Order History</h3>
    </div>
          <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%] max-[768px]:w-[90%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-blue-500 font-medium'>Information</h3>
                    </div>
                    <div className='p-4'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Carrier</FormLabel>
                                <Select placeholder='   Carrier' name='carrier' value={data?.carrier} onChange={handleChange}>
                                    <option>United Arab Emirates</option>
                                    <option>Nigeria</option>
                                </Select>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Price</FormLabel>
                                <Select placeholder='Price' name='price' value={data?.price} onChange={handleChange}>
                                    <option>United Arab Emirates</option>
                                    <option>Nigeria</option>
                                </Select>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Status</FormLabel>
                                <Select placeholder='Status' name='status' value={data?.status} onChange={handleChange}>
                                    <option>United Arab Emirates</option>
                                    <option>Nigeria</option>
                                </Select>
                            </FormControl>

                        </div>
                        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-3">
                          <AddIcon/>  Create
                        </button>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCarrier