import react, { useEffect, useState } from 'react'
import { FormControl, FormLabel, Input, Select, Spinner } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { AddNewClientService } from '../service/addNewClientService'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerByIdService } from '../service/getCustomerByIDservice'
import { useFetcher, useNavigate, useParams } from 'react-router-dom'
import { UpdateUserService } from '../service/updateClientService'
const EditClient=()=>{
    const newData=useSelector((state)=>state?.getCustomerByIdSlice?.data)

    const [data,setData]=useState({
         name:"",
         email:"",
         password:"",
         paymentModel:"",
         status:"",
         Phone:""

    })
    const {id}=useParams()

    useEffect(()=>{
    dispatch(getCustomerByIdService(id))
    },[id])
    const navigate=useNavigate()
    const handleChange=(e)=>{
        const {name,value}=e.target
        setData({
            ...data,
            [name]:value
        })

    }
    const dispatch=useDispatch()
    const handleClick=async(e)=>{
        e.preventDefault()
        const newData={
            data:data,
            id:id
        }
 await dispatch(UpdateUserService(newData)).unwrap()
 
 navigate('/numbersystem/distributor/CustomerPanel')
    }

useEffect(()=>{
  setData({
    name:newData?.customerName,
    email:newData?.customerEmail,
    password:newData?.password,
    paymentModel:newData?.paymentMode,
    status:JSON.stringify(newData?.acountStatus),
    Phone:newData?.phoneNumber
  })
},[newData])

   const loading=useSelector((state)=>state?.UpdateUserSlice?.loading)


    return(
        <> <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
        <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Update Client</h3>
    </div>
          <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%] max-[768px]:w-[90%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-[#8A8D56] font-medium'>Information</h3>
                    </div>
                    <div className='p-4'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Name</FormLabel>
                                <Input  onChange={handleChange} placeholder='Name' name='name' value={data?.name}></Input>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Email</FormLabel>
                                <Input onChange={handleChange} placeholder='Email' name='email' value={data?.email}></Input>

                            </FormControl>

                        </div> <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Phone</FormLabel>
                                <Input onChange={handleChange} placeholder='Phone' name='Phone' type='number' value={data?.Phone}></Input>

                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Password</FormLabel>
                                <Input onChange={handleChange} placeholder='Phone' name='password' type='text' value={data?.password}></Input>

                            </FormControl>

                        </div>
                        <div className=''>
                            {/* <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Confirm Password</FormLabel>
                                <Input placeholder=' Confirm Password'></Input>

                            </FormControl> */}

                        </div>
                        
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customer Type</FormLabel>
                                <Select 
               value={data?.paymentModel==="POST_PAID"?"true":"false"}
               onChange={handleChange}
                  name="paymentModel"
                  >
                       <option value="">
Select
                </option>
                <option value="true">
Postpaid
                </option>
                <option value="false">
                Prepaid
                </option>
               </Select>
                            </FormControl>

                        </div>
                       
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Status</FormLabel>
                                <Select onChange={handleChange} placeholder='Status' name='status' value={data?.status}>
                                    <option value={'true'}>Active</option>
                                    <option value={'false'}>Inactive</option>


                                </Select>

                            </FormControl>

                        </div>
                        <button onClick={handleClick} class="bg-[#8A8D56] hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-3">
                      
                      {
                        loading?(
                            <>
                            <Spinner/>
                            </>
                        ):(
                            <>
                             <AddIcon/>  Save
                            </>
                        )
                      }   
                        </button>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditClient