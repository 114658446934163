import React, { useEffect, useState, useRef } from 'react'
import logo from '../assets/logo2.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MenuItem, MenuList, MenuButton, Menu, Button, DrawerOverlay, DrawerCloseButton, DrawerContent, DrawerBody, DrawerHeader, Drawer, useDisclosure } from '@chakra-ui/react'
import { emitter } from '../dashboard/emit'
import { useDispatch, useSelector } from 'react-redux'
import { getDistributorByIdService } from '../service/getDisIdService'
import { AddIcon } from '@chakra-ui/icons'
const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const disData = useSelector((state) => state?.getDisByIdSlice?.data)


  useEffect(() => {
    const links = document.querySelectorAll('.item');
    links.forEach(link => {
      if (link.pathname === location.pathname) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
  }, [location]);


  const [isOpen1, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen1);
  };



  const [size, setSize] = React.useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClick = (newSize) => {
    setSize(newSize)
    onOpen()
  }

  const sizes = ['xs']

  const handleLogout = () => {
    localStorage.removeItem('disId')
    emitter.emit("logout");
  }


  const handleMouseDown = (event) => {
    if (dropdownRef.current && buttonRef.current) {
      const { top: dropdownTop, left: dropdownLeft, width: dropdownWidth, height: dropdownHeight } = dropdownRef.current.getBoundingClientRect();
      const { top: buttonTop, left: buttonLeft, width: buttonWidth, height: buttonHeight } = buttonRef.current.getBoundingClientRect();
      const { clientX: mouseX, clientY: mouseY } = event;

      // Calculate proximity distance to the dropdown and button
      const isNearDropdown =
        mouseX >= dropdownLeft - 10 &&
        mouseX <= dropdownLeft + dropdownWidth + 10 &&
        mouseY >= dropdownTop - 10 &&
        mouseY <= dropdownTop + dropdownHeight + 10;

      const isNearButton =
        mouseX >= buttonLeft - 10 &&
        mouseX <= buttonLeft + buttonWidth + 10 &&
        mouseY >= buttonTop - 10 &&
        mouseY <= buttonTop + buttonHeight + 10;

      // Close dropdown if the cursor is not near dropdown or button
      if (!isNearDropdown && !isNearButton) {
        setIsOpen(false);
      }
    }
  };
  useEffect(() => {
    if (isOpen1) {
      // Add event listener to track mouse movement
      document.addEventListener('mousemove', handleMouseDown);
    } else {
      // Remove event listener if dropdown is not open
      document.removeEventListener('mousemove', handleMouseDown);
    }

    // Clean up event listener when the component unmounts or when isOpen changes
    return () => {
      document.removeEventListener('mousemove', handleMouseDown);
    };
  }, [isOpen1]);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDistributorByIdService())
  }, [dispatch])

  return (
    <>
      <style>
        {
          `   
            th{
                background:#FBB04B !important;
                 }
                td{
               text-align:center !important; 
                }
           .drwaserdrawer h3{
          background:aliceblue;
          margin-top:10px;
          padding-left:20px;

          }
                .item.active{
                color:orange;
                border-bottom:1px solid orange;

                }
                .item:hover{
                color:#8A8D56;
                border-bottom:1px solid orange;
                }
                @media(max-width:768px){
                .rounded-\[5px\].w-\[60\%\] {
      width: 90%;
  }
                }
                }
                `
        }
      </style>
      <div className='w-full max-[768px]:hidden'>
        <div className='flex justify-between w-[98%] mx-auto mt-4 flex-wrap'>
          <div className=''>
            <h3 className='font-[500]'>
              <Link to={'/numbersystem/distributor/dashboard'}>
                <img src={logo} style={{ width: '100px' }} />
              </Link>
            </h3>
          </div>

          <div className=''>
            <Link to='/numbersystem/distributor/shop' className='item'>
              <h3 className='font-[500]'>Shop</h3>
            </Link>

          </div>

          <div className=''>
            <Link to='/numbersystem/distributor/dashboard' className='item'>
              <h3 className='font-[500]'>Dashboard</h3>
            </Link>

          </div>

          <div className=''>
            <Link to='/numbersystem/distributor/customerOrder' className='item'>
              <h3 className='font-[500]'>Customer Ready Orders</h3>
            </Link>
          </div>

          <div className=''>
            <Link to='/numbersystem/distributor/OrderNumber' className='item'>
              <h3 className='font-[500]'>Order Number</h3>
            </Link>
          </div>

          <div className='' >

            <div className="relative inline-block text-left">
              <div>

                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4  text-sm font-medium t rounded-md  focus:outline-none"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                  ref={buttonRef}
                  onClick={toggleDropdown}
                >
               <h3 style={{fontSize:'16px'}}>   Menu</h3>

                </button>
              </div>

              {isOpen1 && (
                <div
                  className=" z-30 origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  ref={dropdownRef}
                  aria-labelledby="options-menu"
                >

                  <div className="py-1" role="none">

                    <Link
                      to="/numbersystem/distributor/CustomerPanel"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Customer Management
                    </Link>
                    <Link
                      to="/numbersystem/distributor/OrderTracking"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Order Tracking
                    </Link>
                    <Link
                      to="/numbersystem/distributor/creditmanagement"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Credit Management
                    </Link>

                    <Link
                      to="/numbersystem/distributor/termscondition"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Terms&Condition
                    </Link>
                    <Link to='/numbersystem/distributor/AboutUs'
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"

                    >
                      <h3 className='font-[500]'>About us</h3>
                    </Link>
                    <Link
                      to="/numbersystem/distributor/Faq"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      FAQ
                    </Link>

                    <Link
                      to="/numbersystem/distributor/contact"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Contact us
                    </Link>
                    <Link
                      to="/numbersystem/distributor/PrivacyPolicy"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      to="/numbersystem/distributor/termscondition"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Terms & Condition
                    </Link>
                    <Link
                      to="/numbersystem/distributor/ReturnExchange"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={() => setIsOpen(false)}
                    >
                      Return & Exchange
                    </Link>



                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=''>
            <h3 className='font-[500] cursor-pointer' onClick={handleLogout}>Logout</h3>
          </div>
        
          <div className="flex justify-end">
            <h5 style={{ color: 'green' }}>${disData?.balance}</h5>
          </div>
          <div className=''>

            <h5 className='font-bold'>
           Welcome {localStorage.getItem('disname')}
            </h5>
          </div>

        </div>
      </div>






      {/* mobile */}

      <div className='w-full bg-[aliceblue] p-3 block md:hidden  '>
        <div className=' items-center  justify-between w-11/12 mx-auto ' style={{ display: 'flex' }}>
          <div>
            <h3 className='  font-[500]'>
              <Link to={'/numbersystem/distributor/dashboard'}>
                <img src={logo} style={{ width: '100px' }} />
              </Link>
            </h3>
          </div>
          <div className=''>
            <Button
              onClick={() => handleClick('xs')}
              key={'xs'}
            >
           Menu
            </Button>
          </div>
        </div>
      </div>
      <Drawer onClose={onClose} isOpen={isOpen} size={size} className='hidden max-[768px]:block'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{localStorage.getItem('disname')}</DrawerHeader>
          <DrawerBody lineHeight={'45px'} padding={'0px'} className='drwaserdrawer'>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/distributor/shop' className='item'>
                <h3 className='font-[500]'>Shop</h3>
              </Link>

            </div>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/distributor/dashboard' className='item'>
                <h3 className='font-[500]'>Dashboard</h3>
              </Link>

            </div>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/distributor/OrderHistory' className='item'>
                <h3 className='font-[500]'>Order History</h3>
              </Link>
            </div>

            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/distributor/OrderNumber' className='item'>
                <h3 className='font-[500]'>Order Number</h3>
              </Link>
            </div>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/distributor/customerOrder' className='item'>
                <h3 className='font-[500]'>Customer Ready Orders</h3>
              </Link>
            </div>

            <Link
              to="/numbersystem/distributor/ReturnExchange"
              className="item"
              role="menuitem"
              onClick={onClose}

            >
              <h3 className='font-[500]'>  Return & Exchange</h3>
            </Link>

            <Link
              to="/numbersystem/distributor/CustomerPanel"
              className="item"
              role="menuitem"
              onClick={onClose}

            >
              <h3 className='font-[500]'>     Customer Management</h3>
            </Link>
            <Link
              to="/numbersystem/distributor/OrderTracking"
              className="item"
              role="menuitem"
              onClick={onClose}

            >
              <h3 className='font-[500]'>      Order Tracking</h3>
            </Link>
            <Link
              to="/numbersystem/distributor/creditmanagement"
              className="item"
              role="menuitem"
              onClick={onClose}

            >
              <h3 className='font-[500]'>     Credit Management</h3>
            </Link>
            <Link
              to="/numbersystem/distributor/Faq"
              className="item"
              role="menuitem"
              onClick={onClose}

            >
              <h3 className='font-[500]'>      FAQ</h3>
            </Link>
            <Link
              to="/numbersystem/distributor/contact"
              className="item"
              role="menuitem"
              onClick={onClose}

            >
              <h3 className='font-[500]'>     Contact us</h3>
            </Link>

            <Link
              to="/numbersystem/distributor/PrivacyPolicy"
              className="item"
              role="menuitem"
              onClick={onClose}

            >
              <h3 className='font-[500]'>      Privacy Policy</h3>
            </Link>
            <Link
              to="/numbersystem/distributor/termscondition"
              className="item"
              role="menuitem"
              onClick={onClose}

            >
              <h3 className='font-[500]'>   Terms&Condition</h3>
            </Link>
            <Link
              to="/numbersystem/distributor/ReturnExchange"
              className="item"
              role="menuitem"
              onClick={onClose}

            >
              <h3 className='font-[500]'>     Return & Exchange Policy</h3>
            </Link>
            <div className=''>
              <h3 className='font-[500] cursor-pointer' onClick={handleLogout}>Logout</h3>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <div className="flex justify-end items-end mr-2" >
                        <Link to={'/numbersystem/distributor/AddNewNumber'}>
                        <button class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
                            <AddIcon w={5} h={3} boxSize={4} />     Order Numbers
                        </button>
                        </Link>
                    </div>

    </>
  )
}

export default Header