import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { baseUrl } from "../baseUrl/baseUrl";
import { handleError } from "../handleError/handleError";
import { basicAuth } from "../baseUrl/basicAuth";
export const editCustomerService = createAsyncThunk(
  "editCustomerService",
  async (payload) => {
    console.log(payload,'dskcnksjncjk')
    const adminID=localStorage.getItem('disId')
    const string=payload?.data.status
    const stribng=string==='true'

    const data={    
       "paymentMode":payload?.data.paymentMode,
      "user": {
          "name": payload?.data.name,
          "email": payload?.data.email,
          "password": payload?.data.password,
          "phoneNumber": payload?.data.number,
          "createdDate": null,
       "acountStatus":stribng,
       "updatedDate": null
      },
      "passAdminId":{
          "adminId":adminID
      }
  
  }
    try {
      let url = baseUrl + `updateCustomerById/${payload?.id}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.put(url, data, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
