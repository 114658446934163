import React, { useState } from 'react';
import { Stack, Input } from '@chakra-ui/react';
import bgImage from '../assets/Rectangle 9.png';
import bgImage2 from '../assets/newRec.png';
import { AuthService } from '../service/authService';
import { useDispatch } from 'react-redux';

function Login({onLogin}) {
  const [data,setData]=useState({
    distributerEmail:"",
    password:""
  })
  const handleChange=(e)=>{
    const {name,value}=e.target
    setData({
      ...data,
      [name]:value
    })
  }
  const dispatch=useDispatch()
 

  const handleClick = () => {
      dispatch(AuthService(data))
        .unwrap()
        .then((res) => {
          localStorage.setItem('disId', res.distributerId);
          localStorage.setItem('disname', res.distributerName);

          if(res.distributerId){
            onLogin();
          }
        })
        .catch((error) => {
          console.error('Login failed:', error);
        });
      };
  
  return (
    <div style={{ minHeight: '100vh', position: 'relative' }}>
      {/* Top-left image */}
      <div className='absolute top-0 left-0 w-[10%]'>
        <img src={bgImage} alt="Background Top Left" />
      </div>
      
      {/* Bottom-right image */}
      <div className='absolute bottom-0 right-0 w-[10%]'>
        <img src={bgImage2} alt="Background Bottom Right" />
      </div>
      
      {/* Centered login form */}
      <div className="w-full h-screen flex justify-center items-center flex-col" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
        <div className="w-[40%] p-[30px] rounded-md max-[768px]:w-full" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
          <div className="mx-auto">
            <h1 className="text-customBlue text-2xl font-bold flex justify-center">Login</h1>
          </div>
          <Stack spacing={3} className="mt-4">
            <Input variant='filled' placeholder='Enter Your Email'  onChange={handleChange} name="distributerEmail"/>
            <Input variant='filled' placeholder='Password' type='password' name="password" onChange={handleChange}/>
            <button onClick={handleClick} className="bg-customBlue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Login
            </button>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default Login;
