import React, { useEffect ,useState} from "react";
import { PhoneIcon, AddIcon, WarningIcon, ViewIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { getAllCustomerService } from "../service/getAllCustomerService";
import { useDispatch, useSelector } from "react-redux";
import XLSX from 'xlsx';
import { formatDate } from "./date";
import { exportToExcel } from "./export";
import { exportToCSV } from "./export";
import { DeleteCustomerService } from "../service/deleteCustomerServiice";

const CustomerPanel = () => {
    const dispatch=useDispatch()
    const {data}=useSelector((state)=>state?.getAllcustomerSlice)

    const [searchValue,setInputValue]=useState('')
 
     useEffect(()=>{
     dispatch(getAllCustomerService())
     },[dispatch])


     const [filterData, setFilterData] = useState([])
     useEffect(() => {
         if (!searchValue) {
             setFilterData(data); // Reset filterData to original data when searchValue is empty
         } else {
             const newData = data.filter(item =>
                 item.customerName && item.customerName.toLowerCase().includes(searchValue.toLowerCase())||
                 item.customerEmail && item.customerEmail.toLowerCase().includes(searchValue.toLowerCase())

             );
             setFilterData(newData); // Update filterData with filtered data
         }
     }, [searchValue, data]); // Include 'data' in dependencies array
     
     
    const copyToClipboard = () => {
        // Define headers
        const headers = ['customerName', 'customerEmail', 'balance'];
   
        // Prepare the text data
        let textData = '';

        // Add headers
        textData += headers.join('\t') + '\n'; // Use tab (\t) for Copying options, newline (\n) for rows

        // Add rows from filterData
        filterData.forEach(item => {
            // Ensure each item has all the required fields
            const row = [
                item?.customerName || '',       // Ensure default value if missing
                item?.customerEmail || '',  // Ensure default value if missing
                item?.balance || '',   // Ensure default value if missing
            ].join('\t'); // Use tab (\t) for Copying options
            textData += row + '\n'; // Add a newline for each row
        });
        navigator.clipboard.writeText(textData).then(
            () => alert('Table data copied to clipboard!'),
            (err) => console.error('Failed to copy table data: ', err)
        );
    };


   const handleDelete=async(id)=>{
  try{
    const confirm = window.confirm('Are you sure you want to delete this Customer?');
 if(confirm){
    await dispatch(DeleteCustomerService(id)).unwrap()
    dispatch(getAllCustomerService())
 }

  }catch(ee){
    console.log(ee)
  }
   }
    
    
    return (
        <>
            <style>
                {

                    `
                th{
                text-align:center;
                border-right:1px solid white;
                background:rgba(82, 87, 214, 1);
                color:white;
                }
                  td{
                text-align:center;
                }


                
                `
                }
            </style>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Customer Panel</h3>
            </div>

            <div className="mt-5 w-[98%] mx-auto " style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <div className="flex justify-between items-center w-[98%] p-2 mx-auto">
                    <div className="">
                        <h1 className="font-[700] text-blue-500" style={{ color: 'rgb(138, 141, 86)' }}> Your Customer List</h1>
                    </div>
                    <div className="">
                        <Link to='/numbersystem/distributor/AddCustomerPanel'>
                        <button class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
                            <AddIcon w={5} h={3} boxSize={4} />     Register New Customer
                        </button>
                        </Link>
                    </div>
                </div>
            </div>


            <div className="">
                <div className="w-[98%]  max-[768px]:flex-wrap  mx-auto mt-4 flex gap-2 items-center justify-between mb-4 max-[768px]:justify-center max-[768px]:w-[94%] ">
                    <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: '' }} className="text-[rgba(18, 168, 78, 1)]">Copying options</p>
                        </div>
                        <div>
                            <Button onClick={()=>exportToExcel(filterData)}>
                                <img src={exportpng} />
                                Excel
                            </Button>
                        </div>
                        <div>
                            <Button onClick={()=>exportToCSV(filterData)}> 
                                <img src={exportpng} />
                                CSV
                            </Button>
                        </div>
                        <div>
                            <Button onClick={copyToClipboard}>
                            <img src={exportpng} />

                                Copy
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div class='max-w-md mx-auto max-[768px]:flex '>
                            <div style={{border:'1px solid  rgb(138, 141, 86)'}} class="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>

                                <input
                                onChange={(e)=>setInputValue(e.target.value)}
                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 "
                                    type="text"
                                    id="search"
                                    placeholder="Search something.." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <body class="w-full mx-auto bg-gray-100">
                <div class="w-full mx-auto mt-5 overflow-x-auto">
                    <div class="overflow-y-auto max-h-[50vh]">
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3">Id</th>
                                    <th scope="col" class="px-6 py-3">Name</th>
                                    <th scope="col" class="px-6 py-3">Email</th>
                                    
                                    <th scope="col" class="px-6 py-3">Balance </th>

                                    <th scope="col" class="px-6 py-3">Created At</th>
                                    <th scope="col" class="px-6 py-3">Payment Type</th>

                                    <th scope="col" class="px-6 py-3">Status</th>
                                    <th scope="col" class="px-6 py-3">Edit</th>
                                    <th scope="col" class="px-6 py-3">Edit Price</th>


                                    

                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                {
                                    Array.isArray(filterData) && filterData?.map((item,index)=>{
                                        return(
                                            <>
                                            
                                            <tr class="border-b">
                                    <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{index+1}</td>
                                    <td class="px-6 py-4">{item?.customerName}</td>
                                    <td class="px-6 py-4">{item?.customerEmail}</td>
                                    <td class="px-6 py-4 text-green-500 font-medium">${item?.balance}</td>
                                    <td class="px-6 py-4  font-medium">{formatDate(item?.createdDate)}</td>
                                    <td class="px-6 py-4  font-medium">{item?.paymentMode}</td>


                                    <td class={`px-6 py-4  font-medium ${item.acountStatus===true?'text-green-500':"text-red-500"}`}>{item?.acountStatus===true?'Active':"Inactive"}</td>

                                   <Link to={`/editClient/${item?.customerId}`}>
                                    <td class="px-6 py-4 text-blue-500 font-medium text-xl">
                                        <img src={edit} class="w-10 mx-auto" />
                                    </td>
                                    </Link>
                                    <td class="px-6 py-4 text-blue-500 font-medium text-xl">

                                    <Link to={`/editPriceCustomr/${item?.customerId}`}>
                                        <img src={edit} class="w-10 mx-auto" />
                                    </Link>
                                    </td>

                                </tr>
                                            </>
                                        )
                                    })
                                }
                              
                            
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

        </>
    )
}

export default CustomerPanel