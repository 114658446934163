import React, { useState,useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Login from './auth/login';
import Header from './header/header';
import Dashboard from './dashboard/dashboard';
import OrderManagement from './dashboard/orderManagement';
import UserManagement from './dashboard/usermanagment';
import './App.css'
import OrderHistory from './dashboard/OrderHistory';
import OrderTracking from './dashboard/ordertracking';
import CreditManagement from './dashboard/creditmanagement';
import AddCarrier from './dashboard/addCarrier';
import AddCustomerPanel from './dashboard/addCustomer';
import AddNewClient from './dashboard/addNewClient';
import Shop from './dashboard/shop';
import OrderNumber from './dashboard/ordernumber';
import AddNewNumber from './dashboard/addnewNumber';
import CustomerPanel from './dashboard/customerpanel';
import EditClient from './dashboard/upddateClient';



import AboutUs from './otherpages/aboutus';
import FaqSection from './otherpages/faq';
import TermsCondition from './otherpages/termscondition';
import PrivacyPolicy from './otherpages/privacyPolicy';
import ReturnExchange from './otherpages/returnExchange';
import CustomerOrderManagement from './dashboard/getAllCustomerOrder';
import { sucessToast } from './toast/toast';
import { emitter } from './dashboard/emit';
import Contact from './dashboard/contact';
import { useSelector,useDispatch} from 'react-redux';
import { getDistributorByIdService } from './service/getDisIdService';
import Inventory from './dashboard/inventory';
import SellingPriceEdit from './dashboard/editPrice';
import EditPriceCustomer from './dashboard/editCustomerPrice';

function App() {

  const disData=useSelector((state)=>state?.getDisByIdSlice?.data)
  console.log(disData,'disdatattatat')
  const dispatch=useDispatch()
  useEffect(()=>{
    dispatch(getDistributorByIdService())
   },[])
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);

  // Function to handle login
  const handleLogin = () => {
   
    setLogin(true);
    navigate('/numbersystem/distributor/shop'); // Redirect to admin page after login
  };

  const tokenkey = localStorage.getItem('disId');

  useEffect(() => {
    if (tokenkey) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  useEffect(() => {
    emitter.on("logout", () => {
      setLogin(false)
      navigate('/')
    });

  }, []);

useEffect(()=>{
    if(disData?.acountStatus===false){
      localStorage.removeItem('disId');
      setLogin(false);

  return navigate('/')
    }
  },[disData])

  return (
    <>
    {
     login && <Header/>
    }
      <Routes>
        {/* Route for Login */}
        {!login && <Route path="/" element={<Login onLogin={handleLogin} />} />}
        {/* Route for Header and Dashboard */}
        {login && (
          <>
            <Route path="/numbersystem/distributor/dashboard" element={<Dashboard />} />
            <Route path="/numbersystem/distributor/shop" element={<Shop />} />
            <Route path="/numbersystem/distributor/Inventory" element={<Inventory />} />
            <Route path="/priceEdit/:id" element={<SellingPriceEdit />} />


            <Route path="/numbersystem/distributor/OrderHistory" element={<OrderHistory />} />
            <Route path="/numbersystem/distributor/userManagement" element={<UserManagement />} />
            <Route path="/numbersystem/distributor/ordermanagement" element={<OrderManagement />} />
            <Route path="/numbersystem/distributor/OrderTracking" element={<OrderTracking />} />
            <Route path="/numbersystem/distributor/creditmanagement" element={<CreditManagement />} />
            <Route path="/numbersystem/distributor/addCarrier" element={<AddCarrier />} />
            <Route path="/numbersystem/distributor/AddCustomerPanel" element={<AddCustomerPanel />} />
            <Route path="/numbersystem/distributor/AddNewClient" element={<AddNewClient />} />
            <Route path="/numbersystem/distributor/OrderNumber" element={<OrderNumber />} />
            <Route path="/numbersystem/distributor/AddNewNumber" element={<AddNewNumber />} />
            <Route path="/numbersystem/distributor/CustomerPanel" element={<CustomerPanel />} />
            <Route path="/editClient/:id" element={<EditClient />} />
            <Route path="/numbersystem/distributor/customerOrder" element={<CustomerOrderManagement />} />
            <Route path="/editPriceCustomr/:id" element={<EditPriceCustomer />} />


            <Route path="/numbersystem/distributor/AboutUs" element={<AboutUs />} />
            <Route path="/numbersystem/distributor/Faq" element={<FaqSection />} />
            <Route path="/numbersystem/distributor/termscondition" element={<TermsCondition />} />
            <Route path="/numbersystem/distributor/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/numbersystem/distributor/ReturnExchange" element={<ReturnExchange />} />
            <Route path="/numbersystem/distributor/contact" element={<Contact />} />

          </>
        )}

      </Routes>
    </>
  );
}

export default App;
