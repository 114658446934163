import React, { useState, useEffect } from 'react'
import { PhoneIcon, AddIcon, WarningIcon, ViewIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { Button, Input, Spinner, FormControl, FormLabel, Select } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { getAllCustomerOrderHistory } from "../service/getAllcustomerOrderHistoryService";
import { exportToExcel } from "./export";
import { exportToCSV } from "./export";
import { getCustomerOrderTrackingService } from '../service/getCustomerOrderTrackingService'

// import { UpdateOrderStatus } from "../service/updateOrderStatusService";
// import { UpdateDistributorService } from "../service/updateDistributorOrderStatusService";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react'
// import { ge } from "../service/addOrderSimnumberService";
import { addCostomerOrderNumber } from "../service/addOrderForCustomerService";
import { getAllCustomerService } from '../service/getAllCustomerService'
const OrderTracking = () => {
    const [filteredData, setnewFilterData] = useState()
    const [searchValue,setSearchValue]=useState('')



    const { data } = useSelector((state) => state?.getAllcustomerHistorySlice)
    const mydatatatta = useSelector((state) => state?.getCustomerOrderTrackingSlice?.data)

    console.log(data)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllCustomerOrderHistory())
    }, [dispatch])




    const [newDatadata, setData] = useState({
        transerPin: "",
        expiryDate: "",
        planNumber: "",
        accountNumber: ''
    }
    )
    const hanldeInputChange = (e) => {
        const { value, name } = e.target
        setData({
            ...newDatadata,
            [name]: value
        })

    }

    const customerr = useSelector((state) => state?.getAllcustomerSlice?.data)

    useEffect(() => {
        dispatch(getAllCustomerService())
    }, [dispatch])



    const [searchAlgo, setSearchAlgo] = useState({
        name: "",
        startDate: "",
        endData: ""
    })


    const handleClick = async () => {
        dispatch(getCustomerOrderTrackingService(searchAlgo))
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // If the field is `endData`, we need to add one day to the date
        if (name === "endData") {
          const newEndDate = new Date(value);
          newEndDate.setDate(newEndDate.getDate() + 1); // Add one day
          setSearchAlgo({
            ...searchAlgo,
            [name]: newEndDate.toISOString().split("T")[0] // Save the updated end date in yyyy-mm-dd format
          });
        } else {
          setSearchAlgo({
            ...searchAlgo,
            [name]: value
          });
        }
      };
     


    const copyToClipboard = () => {
        // Define headers
        const headers = ['customerName', 'customerEmail', 'availableQuantity','totalCost'];

        // Prepare the text data
        let textData = '';
        textData += headers.join('\t') + '\n'; // Use tab (\t) for Copying options, newline (\n) for rows
        filteredData.forEach(item => {
            const row = [
                item?.customerName || '',
                item?.carrierName || '',
                item?.areaCode || '',
                item?.totalCost || ''
            ].join('\t');
            textData += row + '\n';
        });
        navigator.clipboard.writeText(textData).then(
            () => alert('Table data copied to clipboard!'),
            (err) => console.error('Failed to copy table data: ', err)
        );
    };


    useEffect(() => {
        if (!searchValue) {
            setnewFilterData(mydatatatta);
        } else {
            const filteredData = mydatatatta?.filter(item => 
                (item?.customerName || '').toLowerCase().includes(searchValue.toLowerCase())
            );
            setnewFilterData(filteredData);
        }
    }, [searchValue, mydatatatta]);

    return (
        <>
            <style>
                {
                    `
                th{
                color:white;
            text-align:center;

                }
            
                `
                }
            </style>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto p-2 text-xl max-[768px]:text-center">Order History</h3>
            </div>

            <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className=' font-medium' style={{ color: 'rgb(138, 141, 86)' }}>Get Reports</h3>
                    </div>
                    <div className='p-4'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Client List</FormLabel>
                                <Select placeholder='Select Customer' name='name' onChange={handleChange} >
                                    {
                                        Array.isArray(customerr) && customerr.map((item) => {
                                            return (
                                                <>

                                                    <option value={item?.customerId}>{item?.customerName}</option>
                                                </>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'} >Start Date</FormLabel>
                                <input type='date' name="startDate" onChange={handleChange}></input>
                            </FormControl>
                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>End Date</FormLabel>
                                <input type='date' name="endData" onChange={handleChange}></input>
                            </FormControl>

                        </div>
                        <div>
                            <div className=''>
                                <Button onClick={handleClick}>
                                    Search Order
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <div className="mt-5 w-[98%] mx-auto " style={{ background: 'rgba(243, 251, 254, 1)' }}>

            </div>


            <div className="">
                <div className="w-[98%]  max-[768px]:flex-wrap  mx-auto mt-4 flex gap-2 items-center justify-between mb-4 max-[768px]:justify-center max-[768px]:w-[94%] ">
                    <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: '' }}>Copying options</p>
                        </div>
                        <div>
                            <Button onClick={() => exportToExcel(filteredData||[])}>
                                <img src={exportpng} />
                                Excel
                            </Button>
                        </div>
                        <div>
                            <Button onClick={() => exportToCSV(filteredData||[])}>
                                <img src={exportpng} />
                                CSV
                            </Button>
                        </div>
                        <div>
                            <Button onClick={copyToClipboard}>
                                <img src={exportpng} />
                                Copy
                            </Button>
                        </div>
                        {/* <div>
                            <Button>
                                Copy
                            </Button>
                        </div> */}
                    </div>
                    <div>

                        <div class='max-w-md mx-auto max-[768px]:flex '>
                            <div style={{ border: '1px solid rgb(138, 141, 86)' }} class="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>

                                <input
                                onChange={(e)=>setSearchValue(e.target.value)}
                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 "
                                    type="text"
                                    id="search"
                                    placeholder="Search something.." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <body class="w-full mx-auto bg-gray-100">
                <div class="w-full mx-auto mt-5 overflow-x-auto">
                    <div class="overflow-y-auto max-h-[50vh]">
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-justify">Ref</th>
                                    <th scope="col" class="px-6 py-3">Carier</th>
                                    <th scope="col" class="px-6 py-3">Quantity</th>
                                    <th scope="col" class="px-6 py-3">Area Code</th>
                                    <th scope="col" class="px-6 py-3">Cost </th>
                                    <th scope="col" class="px-6 py-3">Status</th>
                                    <th scope="col" class="px-6 py-3">Status Description</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                {

                                    Array.isArray(filteredData) && filteredData.map((item,index) => {
                                        return (
                                            <>
                                                <tr class="border-b">
                                                    <td class="px-6 text-justify py-4 font-medium text-gray-900 whitespace-nowrap">
                                                        <p>
                                                            Id: {index + 1}
                                                        </p>
                                                        <p>
                                                            Name: {item?.customerName}
                                                        </p>
                                                    </td>
                                                    <td class="px-6 py-4">{item?.carrierName}</td>
                                                    <td class="px-6 py-4 grid grid-cols-2 gap-2 mt-[22px]">
                                                        <Button className="bg-[green-500]">
                                                            {item?.deliverdQuantity}
                                                        </Button>
                                                        <Button className="ml-1">
                                                            {item?.orderQuantity}
                                                        </Button>
                                                    </td>
                                                    <td class="px-6 py-4 text-green-500 font-medium">{item?.areaCode}</td>
                                                    <td class="px-6 py-4  font-medium">${item?.totalCost}</td>
                                                    <td class={`px-6 py-4 ${item.orderStatus == 'Delivered' ? 'text-green-500' : 'text-red-500'} font-medium`} >
                                                        {item?.orderStatus}
                                                    </td>
                                                    <td class="px-6 py-4  font-medium">
                                                        {item?.orderStatusDescription}
                                                    </td>


                                                </tr>
                                            </>
                                        )
                                    })
                                }



                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

        </>
    )
}

export default OrderTracking