import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { getAllAreadCodeService } from "../service/getAllareaCodeService";
const initialState = {

  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};


const getAllAreadCodeSlice = createSlice({
  name: "getAllAreadCodeSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllAreadCodeService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllAreadCodeService.fulfilled, (state, action) => {
      return { ...state,  loading: false ,data:action.payload};
    });
    builder.addCase(getAllAreadCodeService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllAreadCodeSlice.reducer;

