export const AreaCode = [
    { areaCode: 201, location: 'New Jersey' },
    { areaCode: 202, location: 'District of Columbia' },
    { areaCode: 203, location: 'Connecticut' },
    { areaCode: 205, location: 'Alabama' },
    { areaCode: 206, location: 'Washington' },
    { areaCode: 207, location: 'Maine' },
    { areaCode: 208, location: 'Idaho' },
    { areaCode: 209, location: 'California' },
    { areaCode: 210, location: 'Texas' },
    { areaCode: 212, location: 'New York' },
    { areaCode: 213, location: 'California' },
    { areaCode: 214, location: 'Texas' },
    { areaCode: 215, location: 'Pennsylvania' },
    { areaCode: 216, location: 'Ohio' },
    { areaCode: 217, location: 'Illinois' },
    { areaCode: 218, location: 'Minnesota' },
    { areaCode: 219, location: 'Indiana' },
    { areaCode: 220, location: 'Ohio' },
    { areaCode: 223, location: 'Pennsylvania' },
    { areaCode: 224, location: 'Illinois' },
    { areaCode: 225, location: 'Louisiana' },
    { areaCode: 228, location: 'Mississippi' },
    { areaCode: 229, location: 'Georgia' },
    { areaCode: 231, location: 'Michigan' },
    { areaCode: 234, location: 'Ohio' },
    { areaCode: 239, location: 'Florida' },
    { areaCode: 240, location: 'Maryland' },
    { areaCode: 248, location: 'Michigan' },
    { areaCode: 251, location: 'Alabama' },
    { areaCode: 252, location: 'North Carolina' },
    { areaCode: 253, location: 'Washington' },
    { areaCode: 254, location: 'Texas' },
    { areaCode: 256, location: 'Alabama' },
    { areaCode: 260, location: 'Indiana' },
    { areaCode: 262, location: 'Wisconsin' },
    { areaCode: 267, location: 'Pennsylvania' },
    { areaCode: 269, location: 'Michigan' },
    { areaCode: 270, location: 'Kentucky' },
    { areaCode: 272, location: 'Pennsylvania' },
    { areaCode: 276, location: 'Virginia' },
    { areaCode: 279, location: 'California' },
    { areaCode: 281, location: 'Texas' },
    { areaCode: 301, location: 'Maryland' },
    { areaCode: 302, location: 'Delaware' },
    { areaCode: 303, location: 'Colorado' },
    { areaCode: 304, location: 'West Virginia' },
    { areaCode: 305, location: 'Florida' },
    { areaCode: 307, location: 'Wyoming' },
    { areaCode: 308, location: 'Nebraska' },
    { areaCode: 309, location: 'Illinois' },
    { areaCode: 310, location: 'California' },
    { areaCode: 312, location: 'Illinois' },
    { areaCode: 313, location: 'Michigan' },
    { areaCode: 314, location: 'Missouri' },
    { areaCode: 315, location: 'New York' },
    { areaCode: 316, location: 'Kansas' },
    { areaCode: 317, location: 'Indiana' },
    { areaCode: 318, location: 'Louisiana' },
    { areaCode: 319, location: 'Iowa' },
    { areaCode: 320, location: 'Minnesota' },
    { areaCode: 321, location: 'Florida' },
    { areaCode: 323, location: 'California' },
    { areaCode: 325, location: 'Texas' },
    { areaCode: 326, location: 'Ohio' },
    { areaCode: 330, location: 'Ohio' },
    { areaCode: 331, location: 'Illinois' },
    { areaCode: 332, location: 'New York' },
    { areaCode: 334, location: 'Alabama' },
    { areaCode: 336, location: 'North Carolina' },
    { areaCode: 337, location: 'Louisiana' },
    { areaCode: 339, location: 'Massachusetts' },
    { areaCode: 340, location: 'U.S. Virgin Islands' },
    { areaCode: 341, location: 'California' },
    { areaCode: 346, location: 'Texas' },
    { areaCode: 347, location: 'New York' },
    { areaCode: 351, location: 'Massachusetts' },
    { areaCode: 352, location: 'Florida' },
    { areaCode: 360, location: 'Washington' },
    { areaCode: 361, location: 'Texas' },
    { areaCode: 364, location: 'Kentucky' },
    { areaCode: 380, location: 'Ohio' },
    { areaCode: 385, location: 'Utah' },
    { areaCode: 386, location: 'Florida' },
    { areaCode: 401, location: 'Rhode Island' },
    { areaCode: 402, location: 'Nebraska' },
    { areaCode: 404, location: 'Georgia' },
    { areaCode: 405, location: 'Oklahoma' },
    { areaCode: 406, location: 'Montana' },
    { areaCode: 407, location: 'Florida' },
    { areaCode: 408, location: 'California' },
    { areaCode: 409, location: 'Texas' },
    { areaCode: 410, location: 'Maryland' },
    { areaCode: 412, location: 'Pennsylvania' },
    { areaCode: 413, location: 'Massachusetts' },
    { areaCode: 414, location: 'Wisconsin' },
    { areaCode: 415, location: 'California' },
    { areaCode: 417, location: 'Missouri' },
    { areaCode: 419, location: 'Ohio' },
    { areaCode: 423, location: 'Tennessee' },
    { areaCode: 424, location: 'California' },
    { areaCode: 425, location: 'Washington' },
    { areaCode: 430, location: 'Texas' },
    { areaCode: 432, location: 'Texas' },
    { areaCode: 434, location: 'Virginia' },
    { areaCode: 435, location: 'Utah' },
    { areaCode: 440, location: 'Ohio' },
    { areaCode: 442, location: 'California' },
    { areaCode: 443, location: 'Maryland' },
    { areaCode: 445, location: 'Pennsylvania' },
    { areaCode: 458, location: 'Oregon' },
    { areaCode: 463, location: 'Indiana' },
    { areaCode: 469, location: 'Texas' },
    { areaCode: 470, location: 'Georgia' },
    { areaCode: 475, location: 'Connecticut' },
    { areaCode: 478, location: 'Georgia' },
    { areaCode: 479, location: 'Arkansas' },
    { areaCode: 480, location: 'Arizona' },
    { areaCode: 484, location: 'Pennsylvania' },
    { areaCode: 501, location: 'Arkansas' },
    { areaCode: 502, location: 'Kentucky' },
    { areaCode: 503, location: 'Oregon' },
    { areaCode: 504, location: 'Louisiana' },
    { areaCode: 505, location: 'New Mexico' },
    { areaCode: 507, location: 'Minnesota' },
    { areaCode: 508, location: 'Massachusetts' },
    { areaCode: 509, location: 'Washington' },
    { areaCode: 510, location: 'California' },
    { areaCode: 512, location: 'Texas' },
    { areaCode: 513, location: 'Ohio' },
    { areaCode: 515, location: 'Iowa' },
    { areaCode: 516, location: 'New York' },
    { areaCode: 517, location: 'Michigan' },
    { areaCode: 518, location: 'New York' },
    { areaCode: 520, location: 'Arizona' },
    { areaCode: 530, location: 'California' },
    { areaCode: 531, location: 'Nebraska' },
    { areaCode: 534, location: 'Wisconsin' },
    { areaCode: 539, location: 'Oklahoma' },
    { areaCode: 540, location: 'Virginia' },
    { areaCode: 541, location: 'Oregon' },
    { areaCode: 551, location: 'New Jersey' },
    { areaCode: 559, location: 'California' },
    { areaCode: 561, location: 'Florida' },
    { areaCode: 562, location: 'California' },
    { areaCode: 563, location: 'Iowa' },
    { areaCode: 564, location: 'Washington' },
    { areaCode: 567, location: 'Ohio' },
    { areaCode: 570, location: 'Pennsylvania' },
    { areaCode: 571, location: 'Virginia' },
    { areaCode: 573, location: 'Missouri' },
    { areaCode: 574, location: 'Indiana' },
    { areaCode: 575, location: 'New Mexico' },
    { areaCode: 580, location: 'Oklahoma' },
    { areaCode: 585, location: 'New York' },
    { areaCode: 586, location: 'Michigan' },
    { areaCode: 601, location: 'Mississippi' },
    { areaCode: 602, location: 'Arizona' },
    { areaCode: 603, location: 'New Hampshire' },
    { areaCode: 605, location: 'South Dakota' },
    { areaCode: 606, location: 'Kentucky' },
    { areaCode: 607, location: 'New York' },
    { areaCode: 608, location: 'Wisconsin' },
    { areaCode: 609, location: 'New Jersey' },
    { areaCode: 610, location: 'Pennsylvania' },
    { areaCode: 612, location: 'Minnesota' },
    { areaCode: 614, location: 'Ohio' },
    { areaCode: 615, location: 'Tennessee' },
    { areaCode: 616, location: 'Michigan' },
    { areaCode: 617, location: 'Massachusetts' },
    { areaCode: 618, location: 'Illinois' },
    { areaCode: 619, location: 'California' },
    { areaCode: 620, location: 'Kansas' },
    { areaCode: 623, location: 'Arizona' },
    { areaCode: 626, location: 'California' },
    { areaCode: 628, location: 'California' },
    { areaCode: 629, location: 'Tennessee' },
    { areaCode: 630, location: 'Illinois' },
    { areaCode: 631, location: 'New York' },
    { areaCode: 636, location: 'Missouri' },
    { areaCode: 640, location: 'New Jersey' },
    { areaCode: 641, location: 'Iowa' },
    { areaCode: 646, location: 'New York' },
    { areaCode: 650, location: 'California' },
    { areaCode: 651, location: 'Minnesota' },
    { areaCode: 657, location: 'California' },
    { areaCode: 659, location: 'Alabama' },
    { areaCode: 660, location: 'Missouri' },
    { areaCode: 661, location: 'California' },
    { areaCode: 662, location: 'Mississippi' },
    { areaCode: 667, location: 'Maryland' },
    { areaCode: 669, location: 'California' },
    { areaCode: 678, location: 'Georgia' },
    { areaCode: 680, location: 'New York' },
    { areaCode: 681, location: 'West Virginia' },
    { areaCode: 682, location: 'Texas' },
    { areaCode: 689, location: 'Florida' },
    { areaCode: 701, location: 'North Dakota' },
    { areaCode: 702, location: 'Nevada' }
  ];
  