import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { getAllCarriersPriceByDisService } from "../service/getAllcarrirerPricesDisId";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};

const getAllcarrierPriceslice = createSlice({
  name: "getAllcarrierPriceslice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllCarriersPriceByDisService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllCarriersPriceByDisService.fulfilled, (state, action) => {
      return { ...state,  loading: false ,data:action.payload};
    });
    builder.addCase(getAllCarriersPriceByDisService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllcarrierPriceslice.reducer;

