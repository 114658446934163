import { createSlice } from "@reduxjs/toolkit";
import { addOrderTrackingService } from "../viewAllService/addOrderTrackingService";
import { sucessToast } from "../toast/toast";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};

const AddOrderTrackingSlice = createSlice({
    name: "AuthSlice",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(addOrderTrackingService.pending, (state) => {
        return { ...state, loading: true };
      });
      builder.addCase(addOrderTrackingService.fulfilled, (state, action) => {
        return { ...state,  loading: false };
      });
      builder.addCase(addOrderTrackingService.rejected, (state, action) => {
        return { ...state, loading: false, error: "Something went wrong" };
      });
  
      
    },
  });
  


export default AddOrderTrackingSlice.reducer;

