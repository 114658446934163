import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { UpdateOrderManagementStatus } from "../service/updateOrderManagementStatusService";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};


const UpdateManagementStatusSlice = createSlice({
  name: "AuthSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(UpdateOrderManagementStatus.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(UpdateOrderManagementStatus.fulfilled, (state, action) => {
      return { ...state,  loading: false };
    });
    builder.addCase(UpdateOrderManagementStatus.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default UpdateManagementStatusSlice.reducer;

