import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { basicOth } from "../baseUrl/baseUrl";

import { baseUrl } from "../baseUrl/baseUrl";
import { basicAuth } from "../baseUrl/basicAuth";
import { handleError } from "../handleError/handleError";

export const getSimCardNumberByService = createAsyncThunk(
  "getSimCardNumberByService",
  async (id) => {
    try {
      let url = baseUrl + `getSimCardByOrderId/${id}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          "ngrok-skip-browser-warning" : "skip-browser-warning",
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.get(url, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function
      throw error;
    }
  }
);
