import React, { useEffect } from "react";
import { getAllDashboardService } from "../service/getDashboardService";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
  } from 'recharts';
  import MyBarChart from "./barchart";
import LineChartComponent from "./chart2";
import { useDispatch, useSelector } from "react-redux";
import WeekChart from "./weekChart";
import { getDistributorByIdService } from "../service/getDisIdService";
import { getWeeklyDashService } from "../service/getWeeklyDashService";
import { useNavigate } from "react-router-dom";

const Dashboard=()=>{
   
  const dat=useSelector((state)=>state?.getDashboardSlice?.data)
  const disData=useSelector((state)=>state?.getDisByIdSlice?.data)

    const weekdata=useSelector((state)=>state?.getWeekDashSlice?.data)

  const dispatch=useDispatch()
  useEffect(()=>{
   dispatch(getAllDashboardService())
   dispatch(getWeeklyDashService())
   dispatch(getDistributorByIdService())
  },[dispatch])
  

    return(
        <>

         <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Dashboard</h3>
            </div>

        
            <WeekChart weekdata={weekdata}/>
           
<MyBarChart datas={dat}/>

        {/* <ResponsiveContainer width="100%" height={400} className='mt-5'>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="pv" fill="#8884d8" />
      <Bar dataKey="Delivered" fill="#82ca9d" />
    </BarChart>
  </ResponsiveContainer> */}


  {/* <LineChartComponent/> */}
        </>
    )
}

 export default Dashboard