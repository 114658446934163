import React, { useEffect ,useState } from "react";
import { PhoneIcon, AddIcon, WarningIcon, ViewIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { getAllNumberService } from "../service/getAllnumberService";
import { useDispatch, useSelector } from "react-redux";
import { exportToExcel } from "./export";
import { exportToCSV } from "./export";
import { getSimCardNumberByService } from "../service/getSimcardNumberServiceById";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react'
const OrderNumber = () => {
    const handleClose = () => setOpen(false);
    const [size, setSize] = React.useState('2xl')

    const [isOpen2,setOpen]=useState(false)



    const dispatch=useDispatch()
    const {data}=useSelector((state)=>state?.getAllNumberSlice)
    const orderDetail=useSelector((state)=>state?.getSimCardbyIdSlice?.data)

    console.log(data,'data')


    useEffect(()=>{
     dispatch( getAllNumberService())
    },[dispatch])


    const [searchValue,setInputValue]=useState('')

    
    const [filterData, setFilterData] = useState([])
    useEffect(() => {
        if (!searchValue) {
            setFilterData(data); // Reset filterData to original data when searchValue is empty
        } else {
            const newData = data.filter(item =>
                item.dealerEmail && item.dealerEmail.toLowerCase().includes(searchValue.toLowerCase())||
                item.dealerName && item.dealerName.toLowerCase().includes(searchValue.toLowerCase())

            );
            setFilterData(newData); // Update filterData with filtered data
        }
    }, [searchValue, data]); // Include 'data' in dependencies array

    
    const copyToClipboard = () => {
        // Define headers
        const headers = ['Name', 'Email', 'Order Quantity','Area code','AM/PM ?'];
   
        // Prepare the text data
        let textData = '';

        // Add headers
        textData += headers.join('\t') + '\n'; // Use tab (\t) for Copying options, newline (\n) for rows

        // Add rows from filterData
        filterData.forEach(item => {
            // Ensure each item has all the required fields
            const row = [
                item?.dealerName || '',       // Ensure default value if missing
                item?.dealerEmail || '',  // Ensure default value if missing
                item?.orderedQuantity || '',   // Ensure default value if missing
                item?.areaCode || '', 
              item?.timePeriod || ''         // Default to empty if missing


            ].join('\t'); // Use tab (\t) for Copying options
            textData += row + '\n'; // Add a newline for each row
        });
        navigator.clipboard.writeText(textData).then(
            () => alert('Table data copied to clipboard!'),
            (err) => console.error('Failed to copy table data: ', err)
        );
    };
   const handleViewDetails =(orderId)=>{
    dispatch(getSimCardNumberByService(orderId))
    setOpen(true)

   }
    
   const convertToCST = (dateStr) => {
    const date = new Date(dateStr);
    const offset = -6; // CST offset from UTC

    // Adjust for daylight saving time if necessary
    const utcOffset = date.getTimezoneOffset() / 60;
    const cstDate = new Date(date.getTime() + (offset - utcOffset) * 60 * 60 * 1000);

    return cstDate.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });
  };

  const copyorderDetail = () => {
    // Define headers
    const headers = ['Phone No.', 'Account No.', 'Transfer Pin', 'Expiry Date', 'AM/PM ?'];

    // Prepare the text data (initialized with the headers)
    let textData = headers.join('\t') + '\n'; // Use tab (\t) for separating columns, newline (\n) for the row

    // Add rows from orderDetail
    orderDetail?.forEach(item => {
        // Ensure each item has all the required fields, fallback to empty string if missing
        const row = [
            item?.phoneNumber || '',       // Default to empty if missing
            item?.accountNumber || '',     // Default to empty if missing
            item?.pin || '',               // Default to empty if missing
            item?.expiryDate || '',        // Default to empty if missing
            item?.timePeriod || ''         // Default to empty if missing
        ].join('\t' + '       '); // Join each field with a tab and a space between them
        // Add the row to the textData (with newline after each row)
        textData += row + '\n';
    });

    // Copy the table data to clipboard
    navigator.clipboard.writeText(textData).then(
        () => alert('Table data copied to clipboard!'),
        (err) => console.error('Failed to copy table data: ', err)
    );
};
const headers = ['Phone number', 'Account Number', 'Transfer Pin', 'Expiry Date',"AM/PM ?"];
const newData = [
    headers,  // Add the headers first
    ...(Array.isArray(orderDetail) ? orderDetail : []).map(item => {
        // Ensure each item has all the required fields, fallback to empty string if missing
        const row = [
            item?.phoneNumber || '',       // Default to empty if missing
            item?.accountNumber || '',     // Default to empty if missing
            item?.pin || '',               // Default to empty if missing
            item?.expiryDate || '',        // Default to empty if missing
            item?.timePeriod || '',        // Default to empty if missing

        ];
        return row; // Return the row for each item in the array
    })
];


function formatTimestampToUS(timestamp) {
    if(timestamp==null){
        return ''
    }
    const date = new Date(timestamp);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    return date.toLocaleString('en-US', options);
}
    return (
        <>
            <style>
                {

                    `
                th{
                text-align:center;
                border-right:1px solid white;
                background:rgba(82, 87, 214, 1);
                color:white;
                }
                  td{
                text-align:center;
                }


                
                `
                }
            </style>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Order Number</h3>
            </div>

            <div className="mt-5 w-[98%] mx-auto " style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <div className="flex justify-between items-center w-[98%] p-2 mx-auto">
                    <div className="">
                        <h1 className="font-[700] " style={{ color: '#8A8D56' }}> My  Pending Order List</h1>
                    </div>
                    <div className="">
                        <Link to={'/numbersystem/distributor/AddNewNumber'}>
                        <button class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
                            <AddIcon w={5} h={3} boxSize={4} />     Order Numbers
                        </button>
                        </Link>
                    </div>
                </div>
            </div>


            <div className="">
                <div className="w-[98%]  max-[768px]:flex-wrap  mx-auto mt-4 flex gap-2 items-center justify-between mb-4 max-[768px]:justify-center max-[768px]:w-[94%] ">
                    <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: '' }} >Copying options</p>
                        </div>
                        <div>
                            <Button onClick={()=>exportToExcel(filterData)}>
                                <img src={exportpng} />
                                Excel
                            </Button>
                        </div>
                        <div>
                            <Button onClick={()=>exportToCSV(filterData)}>
                                <img src={exportpng} />
                                CSV
                            </Button>
                        </div>
                        <div>
                            <Button onClick={copyToClipboard}> 
                            <img src={exportpng} />
                                Copy
                            </Button>
                        </div>
                    </div>
                    <div>
                    
                        <div class='max-w-md mx-auto max-[768px]:flex '>
                            <div style={{ border: '1px solid #8A8D56' }} class="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>

                                <input
                                onChange={(e)=>setInputValue(e.target.value)}
                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 "
                                    type="text"
                                    id="search"
                                    placeholder="Search something.." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal onClose={handleClose} size={size} isOpen={isOpen2}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Your Order Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
        <div className="flex gap-2">
          <div>
                            <Button onClick={()=>exportToExcel(newData)}>
                                <img src={exportpng} />
                                Excel
                            </Button>
                        </div>
                        <div>
                            <Button onClick={()=>exportToCSV(newData)}>
                                <img src={exportpng} />
                                CSV
                            </Button>
                        </div>
                        <div>
                            <Button onClick={copyorderDetail}>
                            <img src={exportpng} />

                                Copy
                            </Button>
                        </div>
                        </div>
          <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3">Phone Number</th>
                                    <th scope="col" class="px-6 py-3">Account Number</th>
                                    <th scope="col" class="px-6 py-3">Transfer Pin</th>
                                    <th scope="col" class="px-6 py-3">Expiry  Date</th>
                                    <th scope="col" class="px-6 py-3">AM/PM ?</th>

                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                {

                                    Array.isArray(orderDetail) && orderDetail.map((item,index) => {
                                        return (
                                            <>

                                                <tr class="border-b">
                                                    <td class="px-6 text-justify py-4 font-medium text-gray-900 whitespace-nowrap">
                                                      {item?.phoneNumber}
                                                    </td>
                                                    <td class="px-6 text-justify py-4 font-medium text-gray-900 whitespace-nowrap">
                                                            {item?.accountNumber}
                                                      
                                                    </td>
                                                    <td class="px-6 text-justify py-4 font-medium text-gray-900 whitespace-nowrap">{item?.pin}</td>
                                                 
                                                    <td class=" text-justify py-4 font-medium text-gray-900 whitespace-nowrap text-[10px]">
                                                    {item?.expiryDate }

                                                    </td>
                                                    <td class=" text-justify py-4 font-medium text-gray-900 whitespace-nowrap text-[10px]">
                                                    {item?.timePeriod}

                                                    </td>
                                                   
                                                </tr>
                                            </>
                                        )
                                    })
                                }



                            </tbody>
                        </table>
          </ModalBody>
   
        </ModalContent>
      </Modal>

            <body class="w-full mx-auto bg-gray-100">
                <div class="w-full mx-auto mt-5 overflow-x-auto">
                    <div class="overflow-y-auto max-h-[50vh]">
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-justify">Ref</th>
                                    <th scope="col" class="px-6 py-3">Carier</th>
                                    <th scope="col" class="px-[100px] py-3">Quantity</th>
                                    <th scope="col" class="px-6 py-3">Area Code</th>
                                    <th scope="col" class="px-6 py-3">Time Order Made</th>
                                    <th scope="col" class="px-6 py-3">Time Order Completed </th>
                                    <th scope="col" class="px-6 py-3">Cost </th>
                                    <th scope="col" class="px-6 py-3">Status</th>
                                    <th scope="col" class="px-6 py-3">Order Note</th>
                                    <th scope="col" class="px-6 py-3">View Numbers</th>

                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                {
                                    Array.isArray(filterData) && [...filterData].reverse().map((item,index)=>{
                                        return(
                                            <>
                                             <tr class="border-b">
                                    <td class="px-6 text-justify py-4 font-medium text-gray-900 whitespace-nowrap">
                                        <p>
                                            Id: {index+1}
                                        </p>
                                        <p>
                                        Dealer Name: {item?.dealerName}
                                        </p>
                                        <p>
                                        Dealer   Email:{item?.dealerEmail}
                                        </p>
                                        <p>
                                            
                                        </p>
                                    </td>
                                    <td class="px-6 py-4">{item?.carrier}</td>
                                    <td class="px-2 py-4 grid grid-cols-3 items-center  gap-2 mt-[22px]">
                                        <Button className="bg-[green-500]">
                                            {item?.availableQuantity===null?'-':item?.availableQuantity}
                                        </Button>
                                       Ready Out of

                                        <Button className="ml-1">
                                            {item?.orderedQuantity}
                                        </Button>
                                    </td>
                                    <td class="px-6 py-4 text-green-500 font-medium">{item?.areaCode}</td>
                                    <td class="px-6 py-4 font-medium">{formatTimestampToUS(item?.createdDate)}</td>
                                    <td class="px-6 py-4 font-medium">{formatTimestampToUS(item?.updatedDate)}</td>
                                    <td class="px-6 py-4  font-medium">${item?.cost}</td>
                                    <td class="px-6 py-4  font-medium" style={{color:`${item.status==='InActive'?'red':'green'}`}}>
                                        {
                                            item?.status==='InActive'?'Processing':"Delivered"
                                        }
                                    </td>
                                    <td class="px-6 py-4  font-medium">
                                        {item?.statusDescription}
                                    </td>
                                    
                                    <td class="px-6 py-4  font-medium cursor-pointer" onClick={() => handleViewDetails(item?.orderId)} >
                           View Numbers
                                    </td>
                                </tr>
                                            </>
                                        )
                                    })
                                }
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

        </>
    )
}

export default OrderNumber