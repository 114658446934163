import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { getAllCustomerOrderHistory } from "../service/getAllcustomerOrderHistoryService";

const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};


const getAllCustomerHistorySlice = createSlice({
  name: "getAllCustomerHistorySlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllCustomerOrderHistory.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllCustomerOrderHistory.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getAllCustomerOrderHistory.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllCustomerHistorySlice.reducer;

