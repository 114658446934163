import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { baseUrl } from "../baseUrl/baseUrl";
import { basicAuth } from "../baseUrl/basicAuth";
import { handleError } from "../handleError/handleError";
export const UpdatePriceDisService = createAsyncThunk(
  "UpdatePriceDisService",
  async (payload) => {
    console.log(payload)
    const data={
        "carrierName":payload?.carrierName,
        "newPrice":payload?.newPrice  
    }
    try {
    
      let url = baseUrl + `updateCarrierPriceByCustomerId/${payload?.id}`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.put(url, data, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function

      throw error;
    }
  }
);
