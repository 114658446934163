import { createSlice } from "@reduxjs/toolkit";
import { sucessToast } from "../toast/toast";
import { getAllCreditService } from "../service/getAllCreditManageService";

const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};


const getAllCreditSlice = createSlice({
  name: "getAllCreditSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllCreditService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllCreditService.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getAllCreditService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllCreditSlice.reducer;

