import react, { useState } from 'react'
import { FormControl, FormErrorMessage, FormLabel, Input, Select, Spinner } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { AddNewClientService } from '../service/addNewClientService'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sucessToast } from '../toast/toast'
const AddNewClient=()=>{
    const {loading}=useSelector((state)=>state?.adddNewClientSlice)
    const [data,setData]=useState({
         name:"",
         email:"",
         password:"",
         paymentModel:"",
         status:"",
         Phone:""
    })
    const [errors,setError]=useState({
        name:"",
        email:"",
        password:"",
        paymentModel:"",
        status:"",
        Phone:""
   })
   const validate=()=>{
    const errors={}
    if(!data?.name){
        errors.name='Name is Required'
    }
    if(!data?.email){
        errors.email='email is Required'
    }
    if(!data?.password){
        errors.password='password is Required'
    } if(!data?.paymentModel){
        errors.paymentModel='Payment Mode is Required'
    } if(!data?.Phone){
        errors.Phone='Phone is Required'
    }

    return errors
   }
    const handleChange=(e)=>{

        const {name,value}=e.target
        setData({
            ...data,
            [name]:value
        })

    }

    const navigate=useNavigate()
    const dispatch=useDispatch()
    const handleClick=async(e)=>{
        e.preventDefault()

        try{
            const errors=validate()
            setError(errors)
            if(Object.keys(errors).length===0){
      const myyy=     await dispatch(AddNewClientService(data)).unwrap()
      console.log(myyy,'myyymyyy')
     sucessToast(myyy)
     if(myyy=="Customer added successfully"){
        navigate('/numbersystem/distributor/CustomerPanel')
     }
     


            }else{
                console.log('error')
            }

        }catch(err){
            console.log(err)
        }
      
        
    }
    return(
        <> <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
        <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Register New Customer</h3>
    </div>
          <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%] max-[768px]:w-[90%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-[#8A8D56] font-medium'>Information</h3>
                    </div>
                    <div className='p-4'>
                        <div className=''>
                            <FormControl  isInvalid={!!errors?.name}>
                                <FormLabel margin={'10px'} color={'grey'}> Customer Name</FormLabel>
                                <Input  onChange={handleChange} placeholder='Name' name='name' value={data?.name}></Input>
                           <FormErrorMessage>{errors?.name}</FormErrorMessage>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl  isInvalid={!!errors?.email}>
                                <FormLabel margin={'10px'} color={'grey'}>Customer  Email</FormLabel>
                                <Input onChange={handleChange} placeholder='Email' name='email' value={data?.email}></Input>
                                <FormErrorMessage>{errors?.email}</FormErrorMessage>

                            </FormControl>

                        </div> <div className=''>
                            <FormControl  isInvalid={!!errors?.Phone}>
                                <FormLabel margin={'10px'} color={'grey'}> Customer  Phone Number</FormLabel>
                                <Input onChange={handleChange} placeholder='Phone Number' name='Phone' type='number' value={data?.Phone}></Input>
                                <FormErrorMessage>{errors?.Phone}</FormErrorMessage>

                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl isInvalid={!!errors?.password} >
                                <FormLabel margin={'10px'} color={'grey'}>Customer  Password</FormLabel>
                                <Input onChange={handleChange} placeholder='Password' name='password' value={data?.password}></Input>
                                <FormErrorMessage>{errors?.password}</FormErrorMessage>

                            </FormControl>

                        </div>
                        <div className=''>
                            {/* <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Confirm Password</FormLabel>
                                <Input placeholder=' Confirm Password'></Input>

                            </FormControl> */}

                        </div>
                        
                        <div className=''>
                            <FormControl isInvalid={!!errors?.paymentModel}>
                                <FormLabel margin={'10px'} color={'grey'}>Customer  Type</FormLabel>
                                {/* Example: Zelle, Cash App, Paypal */}
                                <Select onChange={handleChange}  name='paymentModel' value={data?.paymentModel}>
                                <option value={''}>
                                    Select

                                    </option>
                                    <option value={'true'}>
                                    Postpaid

                                    </option>
                                    <option value={'false'}>
                                    Prepaid
                                    </option>
                                </Select>
                                <FormErrorMessage>{errors?.paymentModel}</FormErrorMessage>
                           
                            </FormControl>

                        </div>
                       
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Customer  Status</FormLabel>
                                <Select onChange={handleChange} placeholder='Status' name='status' value={data?.status}>
                                    <option value={'active'}>Active</option>
                                    <option value={'inactive'}>Inactive</option>


                                </Select>

                            </FormControl>

                        </div>
                        <button onClick={handleClick} class="bg-[#8A8D56] hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-3">
                          {
                            loading?(
                                <>
                                <Spinner/>
                                </>
                            ):(
                                <>
                                
                                <AddIcon/>  Save
                                </>
                            )
                    }
                         
                                
                           
                        </button>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewClient