import React, { useEffect, useState } from "react";
import { PhoneIcon, AddIcon, WarningIcon, ViewIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { getAllNumberService } from "../service/getAllnumberService";
import { useDispatch, useSelector } from "react-redux";
import { exportToExcel } from "./export";
import { exportToCSV } from "./export";

const OrderHistory = () => {
    const dispatch=useDispatch()
    const [inputvalue,setInputValue]=useState('')
    const {data}=useSelector((state)=>state?.getAllNumberSlice)
    console.log(data,'data')
  


    useEffect(()=>{
     dispatch( getAllNumberService())
    },[dispatch])


    const [filterData, setFilterData] = useState([])
    useEffect(() => {
        if (!inputvalue) {
            setFilterData(data); // Reset filterData to original data when searchValue is empty
        } else {
            const newData = data.filter(item =>
                item.carrier && item.carrier.toLowerCase().includes(inputvalue.toLowerCase())
            );
            setFilterData(newData); // Update filterData with filtered data
        }
    }, [inputvalue, data]); // Include 'data' in dependencies array

    
    const copyToClipboard = () => {
        // Define headers
        const headers = ['Carier', 'Quantity', 'Cost'];
   
        // Prepare the text data
        let textData = '';

        // Add headers
        textData += headers.join('\t') + '\n'; // Use tab (\t) for Copying options, newline (\n) for rows

        // Add rows from filterData
        filterData.forEach(item => {
            // Ensure each item has all the required fields
            const row = [
                item?.carrier || '',       // Ensure default value if missing
                item?.orderedQuantity || '',  // Ensure default value if missing
                item?.cost || '',   // Ensure default value if missing
            ].join('\t'); // Use tab (\t) for Copying options
            textData += row + '\n'; // Add a newline for each row
        });
        navigator.clipboard.writeText(textData).then(
            () => alert('Table data copied to clipboard!'),
            (err) => console.error('Failed to copy table data: ', err)
        );
    };

    
    return (
        <>
            <style>
                {

                    `
                th{
                text-align:center;
                border-right:1px solid white;
                background:rgba(82, 87, 214, 1);
                color:white;
                }
                  td{
                text-align:center;
                }


                
                `
                }
            </style>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Order History</h3>
            </div>

            <div className="mt-5 w-[98%] mx-auto " style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <div className="flex justify-between items-center w-[98%] p-2 mx-auto">
                    <div className="">
                        <h1 className="font-[700] " style={{ color: '#8A8D56' }}> Order History</h1>
                    </div>
                   
                </div>
            </div>


            <div className="">
                <div className="w-[98%]  max-[768px]:flex-wrap  mx-auto mt-4 flex gap-2 items-center justify-between mb-4 max-[768px]:justify-center max-[768px]:w-[94%] ">
                    <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: '' }} className="">Copying options</p>
                        </div>
                        <div>
                            <Button onClick={()=>exportToExcel(filterData)}>
                                <img src={exportpng} />
                                Export
                            </Button>
                        </div>
                        <div>
                            <Button onClick={()=>exportToCSV(filterData)}>
                                <img src={exportpng} />
                                CSV
                            </Button>
                        </div>  <div>
                            <Button onClick={copyToClipboard}>
                            <img src={exportpng} />
                                Copy
                            </Button>
                        </div>
                    </div>
                    <div>
                    
                        <div class='max-w-md mx-auto max-[768px]:flex '>
                            <div style={{ border: '1px solid #8A8D56' }} class="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>

                                <input
                                onChange={(e)=>setInputValue(e.target.value)}
                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 "
                                    type="text"
                                    id="search"
                                    placeholder="Search something.." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <body class="w-full mx-auto bg-gray-100">
    <div class="w-full mx-auto mt-5 overflow-x-auto">
        <div class="overflow-y-auto max-h-[50vh]">
            <table class="w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                    <tr>
                        <th scope="col" class="px-4 py-2 text-justify">Id</th>
                        <th scope="col" class="px-4 py-2">Carrier</th>
                        <th scope="col" class="px-[130px] py-2">Quantity</th>
                        <th scope="col" class="px-4 py-2">Cost</th>
                        <th scope="col" class="px-4 py-2">Status</th>
                        <th scope="col" class="px-4 py-2">Order Note</th>
                    </tr>
                </thead>
                <tbody class="bg-white">
                    {Array.isArray(filterData) && filterData?.filter(item => item.status === 'Delivered').reverse().map((item, index) => {
                        return (
                            <tr className="border-b">
                                <td className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    <p>{index + 1}</p>
                                </td>
                                <td className="px-4 py-4">{item?.carrier}</td>
                                <td className="px-4 py-4 flex gap-2">
                                    <Button className="bg-[green-500]">
                                        {item?.availableQuantity === null ? '-' : item?.availableQuantity}
                                    </Button>
                                    <Button className="ml-1">
                                        {item?.orderedQuantity}
                                    </Button>
                                </td>
                                <td className="px-4 py-4 font-medium">${item?.cost}</td>
                                <td className="px-4 py-4 font-medium" style={{ color: `${item.status === 'InActive' ? 'red' : 'green'}` }}>
                                    {item?.status === 'InActive' ? 'Processing' : item.status}
                                </td>
                                <td className="px-4 py-4 font-medium">
                                    {item?.statusDescription}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    </div>
</body>


        </>
    )
}

export default OrderHistory