import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { getAllTheCopyCarrierByDistributer } from "../service/getAllInventoryByDisIdService";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};




 
const getAllTheCopyCarrierByDistributerSlice = createSlice({
  name: "getAllTheCopyCarrierByDistributerSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllTheCopyCarrierByDistributer.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllTheCopyCarrierByDistributer.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getAllTheCopyCarrierByDistributer.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllTheCopyCarrierByDistributerSlice.reducer;

