import { createSlice } from "@reduxjs/toolkit";
import { getSimCardNumberByService } from "../service/getSimcardNumberServiceById";

import { sucessToast } from "../toast/toast";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};

const getSimCardSliceByIDslice = createSlice({
  name: "getSimCardSliceByIDslice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSimCardNumberByService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getSimCardNumberByService.fulfilled, (state, action) => {
      return { ...state,  loading: false,data: action?.payload};
    });
    builder.addCase(getSimCardNumberByService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
  },
});


export default getSimCardSliceByIDslice.reducer;

