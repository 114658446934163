import { createSlice } from "@reduxjs/toolkit";
import { getOrderTrackingService } from "../viewAllService/getOrderTrackingservice";
import { sucessToast } from "../toast/toast";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};

const getOrderTrackingSlice = createSlice({
  name: "AuthSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getOrderTrackingService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getOrderTrackingService.fulfilled, (state, action) => {
      return { ...state,  loading: false };
    });
    builder.addCase(getOrderTrackingService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
  },
});


export default getOrderTrackingSlice.reducer;

