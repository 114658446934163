import react, { useState } from 'react'
import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { AddNewClientService } from '../service/addNewClientService'
const AddNewClient=()=>{
const [data,setData]=useState({
name:"",
email:"",
password:"",
confirmpassword:"",
paymentmode:"",
status:""

})

    return(
        <> <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
        <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Register New Client</h3>
    </div>
          <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%] max-[768px]:w-[90%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='text-blue-500 font-medium'>Information</h3>
                    </div>
                    <div className='p-4'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Name</FormLabel>
                                <Input placeholder='Name' name='name' value={data.name}></Input>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Email</FormLabel>
                                <Input placeholder='Email' name='email' value={data.email}></Input>

                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Password</FormLabel>
                                <Input placeholder='Password' name='password' value={data.password}></Input>

                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Confirm Password</FormLabel>
                                <Input placeholder=' Confirm Password' value={data.confirmpassword} name='confirmpassword'></Input>

                            </FormControl>

                        </div>
                        
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Payment Mode</FormLabel>
                                <Input placeholder=' Payment Mode' name='paymentmode' value={data?.paymentmode}></Input>

                            </FormControl>

                        </div>
                       
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Status</FormLabel>
                                <Select placeholder='Status' name='status' value={data?.status}>
                                    <option>Active</option>
                                    <option>Inactive</option>


                                </Select>

                            </FormControl>

                        </div>
                        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-3">
                          <AddIcon/>  Save
                        </button>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewClient