import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { AuthService } from "../service/authService";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};

const AddSchoolSlice = createSlice({
  name: "AuthSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(AuthService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(AuthService.fulfilled, (state, action) => {
      return { ...state,  loading: false };
    });
    builder.addCase(AuthService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
  },
});


export default AddSchoolSlice.reducer;

