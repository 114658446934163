import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { getAllCarrierService } from "../service/getAlllCarrirService";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};


const getAllCarrierSlice = createSlice({
  name: "carrierSlcie",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllCarrierService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllCarrierService.fulfilled, (state, action) => {
      return { ...state,  loading: false ,data:action.payload};
    });
    builder.addCase(getAllCarrierService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllCarrierSlice.reducer;

