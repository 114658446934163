import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { getDistributorByIdService } from "../service/getDisIdService";
const initialState = {

  data: [],
  loading: false,
  error: null,
  classNotesList: [],
};


const getDistributorSlice = createSlice({
  name: "getDistributorSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDistributorByIdService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getDistributorByIdService.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getDistributorByIdService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getDistributorSlice.reducer;

