import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { getCustomerOrderTrackingService } from "../service/getCustomerOrderTrackingService";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};


const getCustomerOrderTrackingSlice = createSlice({
  name: "getCustomerOrderTrackingSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCustomerOrderTrackingService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getCustomerOrderTrackingService.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action?.payload };
    });
    builder.addCase(getCustomerOrderTrackingService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getCustomerOrderTrackingSlice.reducer;

