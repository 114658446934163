import react, { useEffect, useState, useRef } from 'react'
import { FormControl, FormLabel, Input, Select, Spinner } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { AddOrderNumberService } from '../service/addOrderNumberService'
import { getAllCarrierService } from '../service/getAlllCarrirService'
import { useDispatch, useSelector } from 'react-redux'
import { AreaCode } from './data'
import { getDistributorByIdService } from '../service/getDisIdService'
import { getAllAreadCodeService } from '../service/getAllareaCodeService'
import { getAllpricingbyDistributorId } from '../service/getAllPricingByDisId'
import { Button, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from "@chakra-ui/react";
import { errorToast, sucessToast } from '../toast/toast'
import { useLocation } from 'react-router-dom'

const AddNewNumber = () => {
    const options = [
        { value: '10', label: '6 Hours' },
        { value: '14', label: '12 Hours' },
        { value: '18', label: '24 Hours' },
        { value: '20', label: '3 days' }
    ];
    const [estimate, setEstimateTime] = useState('')


    console.log(AreaCode, 'AreaCodeAreaCodeAreaCode')
    const carrier = useSelector((state) => state?.getAllCarrierSlice?.data)

    const loading = useSelector((state) => state?.addOrderNumberSlice?.loading)
    const pricedata = useSelector((state) => state?.getpricebydisSlice.data)
    const areacodedata = useSelector((state) => state?.getAllareacodeSlice?.data)
    const disIdData = useSelector((state) => state?.getDisByIdSlice?.data);
    console.log(areacodedata, 'djkvscjsjjs')

    const [data, setData] = useState({
        carrier: "",
        areacode: "",
        quantity: "",
        price: "",
        desc: "",
        expDate: "",
        paymentMode: ""
    })
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllCarrierService())
        dispatch(getDistributorByIdService())
        dispatch(getAllpricingbyDistributorId())
    }, [dispatch])
    useEffect(() => {
        dispatch(getAllAreadCodeService(data.carrier))
    }, [data?.carrier])

    const [carrierData, setCarrierData] = useState()
    console.log(carrierData, 'carrierDatacarrierData')
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })

        if (data.carrier) {
            const selectedCarrier = carrier?.find((item) => item.carrierId === parseInt(data.carrier));
            setCarrierData(selectedCarrier)
        }

    }
    function findPercentage(value, totalCost) {
        switch (value) {
            case '6 Hours':
                return totalCost * 0.25; // 25%
            case '12 Hours':
                return totalCost * 0.125; // 12.5%
            case '24 Hours':
                return totalCost * 0.065; // 6.5%
            case '72 Hours':
                return totalCost * 0; // 0%
            default:
                return 0; // Default to 0 if value is unknown
        }
    }
    

    useEffect(() => {
        const calculateTotalCost = () => {
            if (pricedata?.hasOwnProperty(data.carrier)) {
                const totalCost = data.quantity * pricedata[data.carrier];
                const parseData = data?.expDate ? JSON.parse(data.expDate) : {}; // Safely parse the JSON or default to an empty object
           setEstimateTime(parseData?.valueB)
           const value = findPercentage(parseData.valueB, totalCost);

                const newCost = totalCost +value; // Use 1 if expDate is undefined or null

                setData((prevData) => ({
                    ...prevData,
                    price: newCost.toString(),
                }));
                console.log(totalCost, 'totalCost');
            }
        };

        calculateTotalCost();
    }, [data.quantity, data.carrier, data?.expDate]);


    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();

  console.log(disIdData,'disIdDatadisIdDatadisIdDatadisIdData')

    const handleClick = () => {
        if (data.carrier === '') {
            return alert('Please Select Carrier')
        }else if(data?.areacode===''){
            return alert('Please Select the Areacode')
        }
        else if(data?.quantity===''){
            return alert('Please Select the quantity ')
        }
        else if(data?.expDate===''){
            return alert('Please Select the Delivery Speed')
        }
       
        // Ensure balance is a number
        const balance = parseFloat(disIdData?.balance);
        const newPrice = Math.floor(balance);
    
        if (isNaN(newPrice)) {
            return alert('Invalid balance amount');
        }
        
        // if (data.paymentMode !== 'POST_PAID') {
        //     if (data.price > newPrice) {
        //         return alert('Insufficient Fund for This Order');
        //     }
        // }
         else {
            setIsOpen(true); // Open the confirmation dialog
        }
    };
    
    const handleConfirm = async() => {
        try{
            setIsOpen(false);
       const newdata=   await  dispatch(AddOrderNumberService(data)).unwrap(); // Dispatch the action if 
        if(newdata==='Insufficient funds for Purchase'){
        errorToast('Insufficient funds for Purchase')
        }else{
            sucessToast('Order Created sucessfully')
        }
        }
    catch(err){
        console.log(err)
    }
    };

 const location=useLocation()
    const { selectedValue } = location.state || {}; // Retrieve the selected value from the location state
 
    useEffect(() => {
        // If there's existing data (e.g., from props or external source), set it here
        // For example, you could set it based on a prop or an external API call
        if (selectedValue) {
          setData((prevData) => ({
            ...prevData,
            carrier: selectedValue,
          }));
        }
      }, []); // Empty dependency array means this runs once on component mount
    
    return (
        <>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Order
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to place this order? The order will take  {estimate} to finish
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleConfirm} ml={3}>
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Register New Number</h3>
            </div>
            <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%] max-[768px]:w-[90%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>
                        <h3 className=' font-medium'></h3>
                    </div>
                    <div className='p-4'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Carrier</FormLabel>
                                <Select placeholder='Carrier' name='carrier' onChange={handleChange} value={data?.carrier}>
                                    
                                {
    Array.isArray(carrier) && 
    [...new Set(
        carrier
            .filter(item => item.status === 'Active') // Filter out 'Active' carriers
            .map(item => item.name) // Extract the name of each carrier
    )] // Create a Set and then convert it back to an array
    .map((name) => {
        const item = carrier.find(item => item.name === name); // Find the full item by name
        return (
            <option key={name} value={name}>
                {item.name}
            </option>
        );
    })
}
                                </Select>                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Area Code</FormLabel>
                                <Select placeholder='Area Code' name='areacode' onChange={handleChange} value={data.areacode}>
                                    {
                                        Array.isArray(areacodedata) && areacodedata?.map((item) => {
                                            return (
                                                <>
                                                    <option value={item}>{item} </option>
                                                </>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </div>

                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Quantity</FormLabel>
                                <Input type="number" placeholder='Quantity' name='quantity' onChange={handleChange} value={data?.quantity}></Input>

                            </FormControl>
                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Total Price (in $)</FormLabel>
                                <Input type="number" disabled placeholder='Total Price' name='price' onChange={handleChange} value={data?.price}></Input>

                            </FormControl>

                        </div>

                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Order Note , For Example: Request Specific Zip Code</FormLabel>
                                <Input type="text" placeholder='Order Note , For Example: Request Specific Zip Code' name='desc' onChange={handleChange} ></Input>
                            </FormControl>
                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Delivery Speed</FormLabel>
                                <Select name="expDate" placeholder='Select Deliver Speed' onChange={handleChange}>
                                    {options.map((option) => (
                                        <option key={option.value} value={JSON.stringify({valueA:option?.value,valueB:option?.label})}>
                                            {option.label}
                                        </option>
                                    ))}

                                </Select>
                            </FormControl>
                        </div>
                        <button onClick={handleClick} class="bg-orange-500   text-white font-bold py-2 px-4 rounded mt-3">
                            {
                                loading ? (
                                    <>
                                        <Spinner />
                                    </>
                                ) : (
                                    <>
                                        <AddIcon />
                                        Submit Your Order
                                    </>
                                )
                            }

                        </button>
                        <div>
                        </div>

                    </div>
                </div>
                <div>

                </div>
            </div>
        </>
    )
}

export default AddNewNumber
