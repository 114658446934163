import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { basicOth } from "../baseUrl/baseUrl";

import { baseUrl } from "../baseUrl/baseUrl";
import { basicAuth } from "../baseUrl/basicAuth";
import { handleError } from "../handleError/handleError";



export const AddOrderNumberService = createAsyncThunk(

  "AddOrderNumberService",
  async (payload) => {
    const parseData=JSON.parse(payload?.expDate)
    console.log(payload,'acjkcjh')
    const disId=localStorage.getItem('disId')
    const adminId=localStorage.getItem('adminId')
    //  const newpaymentmode=payload?.paymentMode==='true'
    const data={
      "passDistributer": {
        "distributerId": disId
      },
      "passCustomer": null,
      "deliveryCharges":parseData?.valueA,
      "deliveryUpTo":payload?.valueB,
      "carrierName":payload?.carrier,
      "areaCode": payload?.areacode,
      "zipCode": payload?.zip,
      "orderQuantity": payload?.quantity,
      "totalCost": payload?.price,
      "orderStatusDescription":payload?.desc,
      "orderType":payload?.paymentMode,
      "createdDate": null,
      "updatedDate": null,
      "passAdminId":{
        "adminId":adminId
      }
    }
    
    try {
        const schoolId=localStorage.getItem('schoolId')
      let url = baseUrl + `addOrder`;
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          "ngrok-skip-browser-warning" : "skip-browser-warning",
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.post(url,data, config); // Pass the config object as the third parameter
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function
      throw error;
    }
  }
);
