import { createSlice } from "@reduxjs/toolkit";
import { getAllCustomerService } from "../service/getAllCustomerService";
import { sucessToast } from "../toast/toast";

const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};


const getAllCustomerSlice = createSlice({
  name: "AuthSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllCustomerService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllCustomerService.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getAllCustomerService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllCustomerSlice.reducer;

