import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { basicOth } from "../baseUrl/baseUrl";

import { baseUrl } from "../baseUrl/baseUrl";
import { basicAuth } from "../baseUrl/basicAuth";
import { handleError } from "../handleError/handleError";
import { sucessToast } from "../toast/toast";



export const addnewManageMentCreditService = createAsyncThunk(
 "AddCreditsForCustomerService",
  async (payload, thunkAPI) => {
    const { refund } = payload; // Destructure refund from payload
    const { data } = payload; // Destructure refund from payload


    try {
      console.log(refund, 'refund');
       
      const adminId = localStorage.getItem('adminId');

      const ewdata = {
        "description": data.desc,
        "amount": data.amount,
        "createdDate": null,
        "updatedDate": null,
        "status": refund,
        "passCustomer": {
          "customerId": data.clientName
        }
      };

      let url = baseUrl + 'addManageCreditByCustomerId';
      const config = {
        headers: {
          "Authorization": basicAuth,
          'Content-Type': 'application/json'
        }
      };

      const res = await axios.post(url, ewdata, config);
      sucessToast(res.data)
      return res.data;
    } catch (error) {
      console.error("Error in AddCreditsForCustomerService:", error);
      handleError(error); // Ensure handleError is defined correctly
      throw error;
    }
  }
);
